import React, {useEffect, useState} from 'react';
import {Confirm, SaveButton, SimpleForm, useGetIdentity, useNotify } from 'react-admin';
import { FileField, FileInput } from "react-admin";
import {DataGrid, GridRowModel, GridSelectionModel} from "@mui/x-data-grid";
import Stack from '@mui/material/Stack';
import * as XLSX from "xlsx";

import Button from '@mui/material/Button';
import {WorkBook} from "xlsx";

export function ShopsList() {
    const [rows, setRows] = useState<any[]>([]);
    const [oldRows, setOldRows] = useState<any[]>([]);

    let selectedRows: GridSelectionModel = [];

    const notify = useNotify();

    let userId = "";
    let companyId = 0;
    const { identity, isLoading: identityLoading } = useGetIdentity();

    if(!identityLoading) {
        // @ts-ignore
        userId = identity.id.toString();
        // @ts-ignore
        companyId = identity.companyId
    }


    const columns = [
        { field: "shop_name", headerName: "Shop Name", editable: false, minWidth: 200 },
        { field: "domain_url", headerName: "Shop URL", editable: false, minWidth: 200 },
        { field: "logo_url", headerName: "Bild URL", editable: false, minWidth: 200 },
        { field: "last_updated", headerName: "Letzte Änderungen", editable: false, minWidth: 250 },
    ];

    function setFileRows(file: any) {
        const reader = new FileReader();
        // @ts-ignore
        reader.onload = (evt) => {
            // @ts-ignore
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);

            let fileRows = [];

            let id = 0;

            for (const line of data) {
                // @ts-ignore
                line.id = id;
                // @ts-ignore
                line.userId = userId
                id++;

                // @ts-ignore
                if(line.is_deleted === undefined) {
                    // @ts-ignore
                    line.is_deleted = false;
                }

                // @ts-ignore
                if (!line.last_updated) {
                    // @ts-ignore
                    line.last_updated = new Date(Date.now()).toLocaleString('de-DE');
                }

                // @ts-ignore
                line.companyId = companyId;

                fileRows.push(line);
            }

            setRows(fileRows);
            setOldRows(fileRows);
        };
        if(file.Upload !== null) {
            reader.readAsBinaryString(file.Upload.rawFile);
        }
    }

    function upload() {
        //CHECK IF USER WANTS TO OVERRIDE DATA IF PREVIOUS DATA WAS PRESENT TODO

        const request = new Request(process.env.REACT_APP_BACKEND_API + '/shops/upload-shops', {
            method: 'POST',
            body: JSON.stringify({rows: rows, oldRows: oldRows}),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });

        setOpen(false)

        return fetch(request).then(
            (response) => {
                setOldRows(rows);
                if(response.status !== 500) {
                    notify('Shops wurden hochgeladen!');
                }

                loadData();
                return response.json();
            }
        );
    }

    function loadData() {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/shops/get-shops', {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });

        fetch(request).then(
            (response) => {
                return response.json();
            }
        ).then((shops) => {
            let filteredShops = [];
            for(const shop of shops) {
                if(!shop.last_updated) {
                    shop.last_updated = new Date().toLocaleString('de-DE');
                }else {
                    shop.last_updated = new Date(shop.last_updated).toLocaleString('de-DE');
                }

                shop.companyId = companyId;

                if(!shop.is_deleted) {
                    filteredShops.push(shop);
                }
            }
            setRows(filteredShops);
            setOldRows(shops);

            if(shops.length > 0) {
                notify('Shops wurden geladen')
            } else {
                notify('Es wurden keine Shops gefunden')
            }
            return shops;
        });
    }

    const processRowUpdate = async (newRow: GridRowModel) => {
        const updatedRow = { ...newRow };

        const updatedRows = rows.map((row) => {
            row = row.id === newRow.id ? updatedRow : row
            return row
        })

        setRows(updatedRows);
        //setOldRows(updatedRows);
        return updatedRow;
    };

    const exportGrid = () => {
        let rowItems = rows;

        let data = [];

        if(rows.length === 0) {
            data.push({
                shop_name: '',
                domain_url: '',
                logo_url: '',
                last_updated: '',
                is_deleted: 0
            })
        }

        for (const item of rowItems){
            data.push(
                {
                    shop_name: item.shop_name,
                    domain_url: item.domain_url,
                    logo_url: item.logo_url,
                    last_updated: item.last_updated,
                    is_deleted: item.is_deleted
                });
        }

        const ws = XLSX.utils.json_to_sheet(data);

        let wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, "Shops");

        if(data.length !== 0) {
            XLSX.writeFile(wb as WorkBook, "Shops.xlsx");
            notify(`Shops wurden Exportiert!`, { type: 'success' });
        }else {
            notify(`Keine Shops zum Exportieren!`, { type: 'error' });
        }

    }

    const removeRow = () => {
        if(selectedRows.length > 0) {

            selectedRows.forEach( (selectedId) => {
                rows.forEach((item,index) => {
                    if (item.id === selectedId) {
                        rows[index].is_deleted = true;
                    }
                });
            });

            upload()

            selectedRows = [];
        }else {
            notify('Keine Shops ausgewählt', {type: 'warning'})
        }
    }

    const addRow = () => {
        setOldRows([
            ...rows,
            {
                id: rows.length+1,
                shop_name: '',
                domain_url: '',
                logo_url: '',
                last_updated: '',
                is_deleted: 0
            }
        ]);
        setRows([
            ...rows,
            {
                id: rows.length+1,
                shop_name: '',
                domain_url: '',
                logo_url: '',
                last_updated: '',
                is_deleted: 0
            }
        ]);
    }

    useEffect(() => {
        loadData();
    }, [identityLoading])

    const MyCustomNoRowsOverlay = () => (
        <></>
    );

    const [open, setOpen] = useState(false);

    const openConfirm = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    return (
        <>
            <SimpleForm onSubmit={setFileRows} toolbar={<SaveButton label="IMPORT DATA-SHEET"/>}>
                <FileInput name="Upload" source="Produkte" placeholder="UPLOAD DATA-SHEET">
                    <FileField source="src" title="title" />
                </FileInput >
            </SimpleForm >

            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>

                <Button id="upload" title="Upload" name="Upload" onClick={openConfirm}>Änderungen speichern</Button>

                <Confirm
                    isOpen={open}
                    title="Änderungen speichern ?"
                    content="Es besteht die Möglichkeit, dass Daten überschrieben werden."
                    onConfirm={upload}
                    onClose={handleDialogClose}
                    confirm='Akzeptieren'
                    cancel='Abbrechen'
                />

                <Button id="exportData" onClick={exportGrid}>Data-Sheet herunterladen</Button>
                <Button id="loadData" onClick={loadData}>Lade Daten</Button>

                <Button size="small" onClick={removeRow}>
                    Ausgewählte Datensätze Löschen
                </Button>
            </Stack>

            <DataGrid
                autoHeight={true}
                pageSize={10}
                checkboxSelection
                columns={columns}
                rows={rows}
                processRowUpdate={processRowUpdate}
                components={{NoRowsOverlay: MyCustomNoRowsOverlay}}
                onProcessRowUpdateError={(error) => {console.log(error)}}
                experimentalFeatures={{ newEditingApi: true }}
                onSelectionModelChange={(ids) => {
                  selectedRows = ids;
                }}
            />
        </>
    )

    /*<Button size="small" onClick={addRow}>
        Zeile Hinzufügen
    </Button>
*/

}

export default {
    list: ShopsList,
    options: { label: 'Shops' },
};