import React, {useEffect, useState} from 'react';
import {Confirm, SaveButton, SimpleForm, useGetIdentity, useNotify, usePermissions} from 'react-admin';
import { FileField, FileInput } from "react-admin";
import {DataGrid, GridRowModel, GridSelectionModel} from "@mui/x-data-grid";
import * as XLSX from "xlsx";

import Button from '@mui/material/Button';
import {WorkBook} from "xlsx";
import Stack from "@mui/material/Stack";
import {FcRegisteredTrademark} from "react-icons/fc";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputLabel,
    NativeSelect,
    TextField
} from "@mui/material";

export function SheetJSReact() {
    const initalState = {
        id: 0,
        userId: '',
        companyId: '',
        brand: '',
        landingpage: '',
        logo: '',
        buyButton: '',
        colorCode: '',
        category_one: '',
        category_two: '',
        last_updated: '',
        is_deleted: 0
    };

    const [rows, setRows] = useState<any[]>([]);
    const [oldRows, setOldRows] = useState<any[]>([]);

    const [users, setUsers] = useState<any[]>([]);
    const [user, setUser] = useState<any>();

    const [brandData, setBrandData] = useState<any>(initalState);

    const [openChangeModal, setOpenChangeModal] = useState(false);

    const [rowsSelected, setRowsSelected] = useState<GridSelectionModel>([]);

    const notify = useNotify();

    const { identity, isLoading: identityLoading } = useGetIdentity();

    const {permissions,isLoading} = usePermissions();

    if(!identityLoading) {
        if(!user) {
            setUser({userId: identity?.id.toString(), companyId: identity?.companyId})
        }
    }

    const columns = [
        { field: "edit", headerName: "Bearbeiten", editable: false, minWidth: 100, renderCell: (params: any ) => {
                return (<>
                    <Button
                        onClick={() => {
                            setBrandData(params.row)
                            setOpenChangeModal(true)
                        }}
                        variant="contained"
                    >
                        Edit
                    </Button>
                </>)
            } },
        { field: "brand", headerName: "Marke", editable: false, minWidth: 150 },
        { field: "landingpage", headerName: "Landingpage", editable: false, minWidth: 150 },
        { field: "logo", headerName: "Logo", editable: false, minWidth: 150 },
        { field: "buyButton", headerName: "BuyButton Download", editable: false, minWidth: 200 },
        { field: "colorCode", headerName: "Farb Code", editable: false, minWidth: 150 },
        { field: "category_one", headerName: "Kategory Eins", editable: false, minWidth: 200 },
        { field: "category_two", headerName: "Kategory Zwei", editable: false, minWidth: 200 },
        { field: "last_updated", headerName: "Letzte Änderungen", editable: false, minWidth: 150 },
        //{ field: "is_deleted", headerName: "Löschen", editable: false, minWidth: 150, type: 'boolean' },
    ];

    function setFileRows(input: any) {
        const file = input.target.files[0];
        const reader = new FileReader();

        // @ts-ignore
        reader.onload = (evt) => {
            // @ts-ignore
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);

            let fileRows = [];

            let id = 0;

            for (const line of data) {
                // @ts-ignore
                line.id = id;

                // @ts-ignore
                line.userId = user.userId;

                id++;

                // @ts-ignore
                if(line.is_deleted === undefined || line.is_deleted !== 1 || isNaN(line.is_deleted)) {
                    // @ts-ignore
                    line.is_deleted = 0;
                }

                // @ts-ignore
                if (!line.last_updated) {
                    // @ts-ignore
                    line.last_updated = new Date(Date.now()).toLocaleString('de-DE');
                }

                // @ts-ignore
                line.companyId = user.companyId;

                // @ts-ignore
                if (rows.some((entry) => entry.brand === line.brand)) {
                    continue;
                }

                fileRows.push(line);
            }

            // eslint-disable-next-line no-restricted-globals
            if(confirm('Datei hochladen ?')) {
                createNewBrandItems(fileRows);

                setRows(fileRows);
            }
        };
        if(file !== null) {
            reader.readAsBinaryString(file);
        }
    }

    function createNewBrandItems(newItems: any) {
        let request = null
        if(!isLoading) {
            if(user.userId != identity?.id) {
                request = new Request(process.env.REACT_APP_BACKEND_API + '/brands/create-new-brands', {
                    method: 'POST',
                    body: JSON.stringify({userId: user.userId, brandData: newItems}),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                    credentials: 'include',
                });
            } else {
                request = new Request(process.env.REACT_APP_BACKEND_API + '/brands/create-new-brands', {
                    method: 'POST',
                    body: JSON.stringify({brandData: newItems}),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                    credentials: 'include',
                });
            }

            return fetch(request).then(
                (response) => {
                    if(response.status !== 500) {
                        notify('Marken wurden hochgeladen!');
                    }

                    loadData();
                    return response.json();
                }
            );
        }
    }

    function updateBrandData(brandData: any) {
        let route = '/brands/update-brands';

        if(brandData.delete !== undefined) {
            route = '/brands/delete-brands';
            brandData = brandData.rows;
        }

        let request = null
        if(!isLoading) {
            if(user.userId !== identity?.id) {
                request = new Request(process.env.REACT_APP_BACKEND_API + route, {
                    method: 'POST',
                    body: JSON.stringify({userId: user.userId, brandData: brandData}),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                    credentials: 'include',
                });
            } else {
                request = new Request(process.env.REACT_APP_BACKEND_API + route, {
                    method: 'POST',
                    body: JSON.stringify({brandData: brandData}),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                    credentials: 'include',
                });
            }

            return fetch(request).then(
                (response) => {
                    if(response.status !== 500) {
                        notify('Marken wurden hochgeladen!');
                    }

                    loadData();
                    return response.json();
                }
            );
        }
    }

    function loadData() {
        //const request = new Request('http://localhost:3000/brands/get-data', {
        let request = null;
        if(!isLoading && user !== undefined) {
            request = new Request(process.env.REACT_APP_BACKEND_API + '/brands/get-data', {
                method: 'POST',
                body: JSON.stringify({userId: user.userId}),
                headers: new Headers({'Content-Type': 'application/json'}),
                credentials: 'include',
            });

            fetch(request).then(
                (response) => {
                    return response.json();
                }
            ).then((brands) => {
                let filteredBrands = [];
                for (const brand of brands) {
                    if (!brand.last_updated) {
                        brand.last_updated = new Date().toLocaleString('de-DE');
                    } else {
                        brand.last_updated = new Date(brand.last_updated).toLocaleString('de-DE');
                    }

                    brand.companyId = user.companyId;

                    if (!brand.is_deleted) {
                        filteredBrands.push(brand);
                    }
                }

                setRows(filteredBrands);
                setOldRows(brands);
                if (brands.length > 0) {
                    notify('Marken wurden geladen')
                } else {
                    notify('Es wurden keine Marken gefunden')
                }

                return brands;
            });
        }
    }

    const processRowUpdate = async (newRow: GridRowModel) => {
        if (newRow.name === "") {
            throw new Error("name is empty");
        }
        const updatedRow = { ...newRow };

        const updatedRows = rows.map((row) => {
            row = row.id === newRow.id ? updatedRow : row
            return row
        })

        setRows(updatedRows);
        return updatedRow;
    };

    const exportGrid = () => {
        let rowItems = rows;

        let data = [];

        if(rows.length === 0) {
            data.push(  {
                brand: '',
                landingpage: '',
                logo: '',
                buyButton: '',
                colorCode: '',
                category_one: '',
                category_two: '',
                last_updated: '',
                //is_deleted: 0
            })
        }

        for (const item of rowItems){
            data.push(
                {
                    brand: item.brand,
                    landingpage: item.landingpage,
                    logo: item.logo,
                    buyButton: item.buyButton,
                    colorCode: item.colorCode,
                    category_one: item.category_one,
                    category_two: item.category_two,
                    last_updated: item.last_updated,
                    //is_deleted: item.is_deleted
                });
        }

        const ws = XLSX.utils.json_to_sheet(data);

        let wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, "Marken");

        if(data.length !== 0) {
            XLSX.writeFile(wb as WorkBook, "Marken.xlsx");
            notify(`Marken wurden Exportiert!`, { type: 'success' });
        }else {
            notify(`Keine Marken zum Exportieren!`, { type: 'error' });
        }

    }

    const removeRow = () => {
        if(rowsSelected.length > 0) {
            let selectedItems: any[] = [];
            rowsSelected.forEach( (selectedId) => {
                rows.forEach((item,index) => {
                    if (item.id === selectedId) {
                        rows[index].is_deleted = 1;
                        selectedItems.push(rows[index]);
                    }
                });
            });

            // eslint-disable-next-line no-restricted-globals
            if(confirm('Ausgewählte Einträge löschen ?')) {
                updateBrandData({delete: true, rows: selectedItems})
            }

        }else {
            notify('Keine Produkte ausgewählt', {type: 'warning'})
        }
    }

    async function getUsers() {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/users/get-admin-users', {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });

        const data = await fetch(request).then(
            (response) => {
                return response.json();
            }
        )
        setUsers(data);
    }


    const handleChangeDialogClose = () => {
        setBrandData(initalState);
        setOpenChangeModal(false)
    };

    const handleChangeModalConfirm = () => {
        brandData.last_updated = new Date(Date.now()).toLocaleString('de-DE');

        let fileRows = [...rows];

        for (const data of fileRows) {
            const index = fileRows.indexOf(data);
            if(data.id === brandData.id) {
                fileRows[index] = brandData;}
        }

        setRows(fileRows)

        updateBrandData(brandData);

        setOpenChangeModal(false);
        setBrandData(initalState);

    }
    const handleAdminUserChange = (event: any) => {
        const user = users.find(elem => elem.user_id.toString() === event.target.value);

        setUser({userId: user.user_id.toString(), companyId: user.company_id})

    }

    useEffect(() => {
        if(!isLoading && permissions.isAdmin) {
            getUsers();
        }

        loadData();

    }, [user,isLoading])

    return (
        <>
            <h3><FcRegisteredTrademark/> Marken</h3>
            <div style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', display: 'flex'}}>
                <div style={{width: '50%'}}>
                    <p>Zum Bearbeiten von Marken, musst Du diese zuerst hochladen.<br/></p>
                    <ol type="1">
                        <li>Klicke auf  "Data Sheet herunterladen"</li>
                        <li>Trage die Daten in die Muster-Excel ein</li>
                        <li>Klicke danach auf "Import Data Sheet"</li>
                    </ol>
                </div>
                <div style={{width: '50%'}}>
                    <p>
                        Du willst <b>nachträglich</b> Daten anpassen? Verfahre wieder wie in Schritt 1-3 beschrieben.<br/>
                        Du willst Daten <b>löschen</b>? Du kannst Datensätze markieren und mit Klick auf "Ausgewählte Datensätze löschen" diese entfernen.<br/>
                        Oder in der Excel das Flag is_deleted auf 1 setzen. Bereits gelöschte Artikel werden nicht in Excel exportiert
                    </p>
                </div>
            </div>
            <input
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                onChange={setFileRows}
            />
            <InputLabel htmlFor="contained-button-file" style={{width: '100%'}}>
                <Button variant="contained" color="primary" component="span" style={{marginBottom: '10px', marginTop: '10px', width: '100%'}}>
                    IMPORT DATA SHEET
                </Button>
            </InputLabel>
            {!isLoading && permissions.isAdmin ?
                <>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    User
                </InputLabel>
                <NativeSelect
                    defaultValue={user ? user.userId : identity?.id}
                    inputProps={{
                        name: 'user',
                        id: 'uncontrolled-native',
                    }}
                    onChange={handleAdminUserChange}
                >
                    {users.map((user) => (
                        <option key={user.user_id} value={user.user_id}>{user.email}</option>
                    ))}
                </NativeSelect> </>: null
            }
            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                <Button id="exportData" onClick={exportGrid}>Data-Sheet herunterladen</Button>
                <Button id="loadData" onClick={loadData}>Lade Daten</Button>

                <Button size="small" onClick={removeRow}>
                    Ausgewählte Datensätze Löschen
                </Button>
            </Stack>

            <DataGrid
                autoHeight={true}
                pageSize={10}
                checkboxSelection
                columns={columns}
                rows={rows}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={(error) => {console.log(error)}}
                experimentalFeatures={{ newEditingApi: true }}
                onSelectionModelChange={(ids) => {
                    setRowsSelected(ids);
                }}
            />

            <Dialog open={openChangeModal} onClose={handleChangeDialogClose}>
                <DialogTitle>Marke bearbeiten</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ändere bereits angelegte Produkte
                    </DialogContentText>
                    <TextField
                        defaultValue={brandData.brand}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Marken Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {brandData.brand = ev.target.value}}
                    />
                    <TextField
                        defaultValue={brandData.landingpage}
                        autoFocus
                        margin="dense"
                        id="landingpage_url"
                        label="Landingpage"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {brandData.landingpage = ev.target.value}}
                    />
                    <TextField
                        defaultValue={brandData.logo}
                        autoFocus
                        margin="dense"
                        id="logo"
                        label="Logo"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {brandData.logo = ev.target.value}}
                    />
                    <TextField
                        defaultValue={brandData.category_one}
                        autoFocus
                        margin="dense"
                        id="category_one"
                        label="Kategorie Eins"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {brandData.category_one = ev.target.value}}
                    />
                    <TextField
                        defaultValue={brandData.category_two}
                        autoFocus
                        margin="dense"
                        id="category_two"
                        label="Kategorie Zwei"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {brandData.category_two = ev.target.value}}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleChangeDialogClose}>Abbrechen</Button>
                    <Button onClick={handleChangeModalConfirm}>Bestätigen</Button>
                </DialogActions>
            </Dialog>
        </>
    )


        /*<Button size="small" onClick={addRow}>
        Zeile Hinzufügen
    </Button>

    */
}

export default {
    list: SheetJSReact,
    options: { label: 'Marken' },
};