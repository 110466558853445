import React, {useEffect, useState} from "react";
import {FcPositiveDynamic} from "react-icons/fc";
import AuctionTable from "./AuctionTable";
import axios from "axios";

export function Boerse() {
    // https://mui.com/material-ui/react-table/#collapsible-table
    return(
        <>
        <h3><FcPositiveDynamic /> Die Prosoom Börse</h3>
        <p>
            Hier in der <i>Prosoom Börse</i> werden dir Warenkörbe, die von Endkunden zum Bieten freigegeben wurden, angezeigt.
            <br/>Du siehst die Warenkörbe, die für Deinen Shop relevant sind.
            <br/> Du möchtest auch Warenkörbe sehen, die z.B. Produkte enthalten, die du nicht in Deinem Shop anbietest? Dann melde dich gerne bei
            <span style={{marginLeft: 5}}><a href="mailto:william.ludwig-schulte@prosoom.com?subject=Anfrage%20erweiterte%20Ansicht%20Warenk%C3%B6rbe%20in%20der%20Prosoom%20B%C3%B6rse">William</a> &#128578;</span>
        </p>
            <p>Und so funktioniert es:</p>
            <ul style={{marginTop: 0}}>
                <li>Wähle einen Warenkorb aus der Liste unten aus und erstelle ein Gebot.</li>
                <li>Alternativ kannst du auch direkt über den "Sofort Deal" Button den Wunschpreis des Endkunden annehmen</li>
            </ul>
            <AuctionTable/>
        </>
    )
    
}

export default {
    list: Boerse,
    options: { label: 'Börse' },
};