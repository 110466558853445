import * as React from 'react';
import {
    AppBar,
    Logout, ReferenceInput,
    SelectInput, SimpleForm,
    useAuthProvider,
    useGetIdentity,
    useRedirect,
    useRefresh,
    UserMenu
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    useMediaQuery,
    Theme, Button,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import logo from "../img/Prosoom_solutions_Logo_Slogan.svg";
import {useContext} from "react";
import {CompanyContext} from "../components/Context/CompanyContext";

const ConfigurationMenu = React.forwardRef((props, ref) => {
    return (
        <MenuItem
            component={Link}
            // @ts-ignore
            ref={ref}
            {...props}
            to="/base-data"
        >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>Herstellerangaben</ListItemText>
        </MenuItem>
    );
});
const CustomUserMenu = () => (
    <UserMenu>
        <ConfigurationMenu />
        <Logout />
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    const {identity} = useGetIdentity();

    const authProvider = useAuthProvider();

    const redirect = useRedirect();
    const refresh = useRefresh()

    const changedUser = sessionStorage.getItem('adminChangedUser') ? true : false;

    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );

    const {setActiveCompanyId} = useContext(CompanyContext)

    return (
        <>
            {changedUser ?
                <Box sx={{px: 1,width: '100%', backgroundColor: 'red', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <p style={{color: 'white'}}>Eingeloggt als: {identity?.email}</p>
                    <Button variant={"contained"} sx={{p: 0.5, px:1, color: 'white'}} onClick={async (e) => {
                        e.preventDefault();

                        const adminToken = sessionStorage.getItem('adminUserToken');

                        const response = await authProvider.returnToAdminAccount({token:adminToken});
                        setActiveCompanyId(response.companyId)

                        redirect('/#/welcome');
                        refresh();
                    }}>Zurück zum Admin Account</Button>
                </Box>
                : null
            }

            <AppBar
                {...props}
                color="inherit"
                elevation={1}
                userMenu={<CustomUserMenu />}
            >
                <img style={{height: "50px", paddingLeft: 50}} src={logo} alt="Prosoom Logo"/>
                {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
            </AppBar>
        </>
    );
};

export default CustomAppBar;
