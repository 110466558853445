import React, {useContext, useEffect, useState} from 'react';
import {
    Show,
    TextField,
    TextInput,
    SimpleForm,
    Edit,
    required,
    useGetIdentity,
    SimpleShowLayout,
    SaveButton,
    Toolbar,
    ListButton,
    usePermissions,
    ImageField,
    EditButton,
    TopToolbar,
} from 'react-admin';
import {FcHome, FcLeft} from "react-icons/fc";
import {Box, Checkbox, Divider, FormControlLabel, InputLabel, NativeSelect, Typography} from "@mui/material";
import {CompanyContext} from "../Context/CompanyContext";

const UserEditToolbar = ({props, dataHintChecked}:{props: any, dataHintChecked: boolean}) => {
    return (
        <Toolbar {...props} >
            <SaveButton type='button' disabled={!dataHintChecked} label={'Bestätigen'} />
            <ListButton  label="Zurück" icon={<FcLeft/>} style={{position:"absolute",right: 25}}/>
        </Toolbar>
    )
};

const styles = {
    width: "50%",
    borderRadius: "20px",
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    fontWeight: "400",
    fontSize: "1rem",
}

export const UserDataEdit = (props: any) => {
    const [dataHintChecked, setDataHintChecked] = useState(false);

    return (<>
            <h2><FcHome/> Herstellerangaben bearbeiten</h2>
            <Edit {...props}>
                <SimpleForm toolbar={<UserEditToolbar props={props} dataHintChecked={dataHintChecked} />}  {...props}>
                    <TextInput source="name" validate={[required()]} style={styles} label={'Firma'}></TextInput>
                    <TextInput source="companyRootUrl" validate={[required()]} style={styles} label={'Firmen Url'}></TextInput>
                    <TextInput source="street" validate={[required()]} style={styles} label={'Strasse'}></TextInput>
                    <TextInput source="plz" validate={[required()]} style={styles} label={'Postleitzahl'}></TextInput>
                    <TextInput source="city" validate={[required()]} style={styles} label={'Stadt'}></TextInput>
                    <TextInput source="state" validate={[required()]} style={styles} label={'Land'}></TextInput>
                    <TextInput source="phone" style={styles} label={'Telefon'}></TextInput>
                    <TextInput source="email" style={styles} label={'E-Mail'}></TextInput>
                    <TextInput source="youtubeUrl" style={styles} label={'Youtube'}></TextInput>
                    <TextInput source="facebookUrl" style={styles} label={'Facebook'}></TextInput>
                    <TextInput source="instagramUrl" style={styles} label={'Instagram'}></TextInput>

                    <TextInput source="logoUrl" style={styles} label='Shop Logo'/>
                    <ImageField source="logoUrl" title="shop_logo" sx={{width: "15%", height: "10%", position: "absolute", right: "10%", top: "30%"}}/>
                    <Box>
                        <Divider/>
                         <Typography mt={2} fontWeight={"bold"}>Hinweis zur Verwendung der Stammdaten <span style={{color: "red"}}>*</span></Typography>
                        <FormControlLabel
                            control={<Checkbox checked={dataHintChecked} onChange={() => setDataHintChecked(!dataHintChecked)}  />}
                            label={<p>Wir stimmen hiermit zu, dass die Stammdaten von Prosoom für das Reporting-Tool "Market Intelligence" und der Verbesserung der Suche <a href="https://prosoom.com">www.prosooom.com</a> verwendet werden dürfen.</p>} />
                    </Box>
                </SimpleForm>
            </Edit>
        </>
    );
}

export const BaseDataShowUser = (props: any) => {
    const { identity, isLoading: identityLoading } = useGetIdentity();

    const {activeCompanyId} = useContext(CompanyContext)

    const CustomEditButton = () =>{
        return(<TopToolbar>
            <EditButton label={'Meine Herstellerangaben Bearbeiten'} />
        </TopToolbar>)
    }

    if(identityLoading || identity?.companyId === null) {
        return (<></>)
    }

    return (
        <>
            <h3><FcHome/> Herstellerangaben</h3>
            <Show id={activeCompanyId} actions={<CustomEditButton/>}>
                <SimpleShowLayout>
                    <TextField source="name" label={'Firma'} style={styles}></TextField>
                    <TextField source="companyRootUrl" label={'Firmen Url'} style={styles}></TextField>
                    <TextField source="street" label={'Strasse'} style={styles}></TextField>
                    <TextField source="plz" label={'Postleitzahl'} style={styles}></TextField>
                    <TextField source="city" label={'Stadt'} style={styles}></TextField>
                    <TextField source="state" label={'Land'} style={styles}></TextField>
                    <TextField source="phone" label={'Telefon'} style={styles}></TextField>
                    <TextField source="email" label={'E-Mail'} style={styles}></TextField>
                    <TextField source="youtubeUrl" label={'Youtube'} style={styles}></TextField>
                    <TextField source="facebookUrl" label={'Facebook'} style={styles}></TextField>
                    <TextField source="instagramUrl" label={'Instagram'} style={styles}></TextField>
                    <ImageField source="logoUrl" label={''} sx={{width: "15%", height: "10%", position: "absolute", right: "10%", top: "30%"}}></ImageField>
                </SimpleShowLayout>
            </Show>
        </>
    );
}


export default {
    list: BaseDataShowUser,
    edit: UserDataEdit,
    options: { label: 'Herstellerangaben' },
};