import React, {useEffect, useRef} from 'react';
import {useGetIdentity} from "react-admin";

export default function TableauEmbedded() {
    const ref = useRef(null);
    const {identity,isLoading} = useGetIdentity();

    useEffect(() => {
       if(!isLoading && identity && identity.dashUrl !== "" && identity.dashUrl !== null) {
           // @ts-ignore
           if(ref.current !== null && ref.current.childNodes.length === 0) {
               let item = document.createElement('tableau-viz')

               const mainContentContainer = document.getElementById("main-content")
               // @ts-ignore
               const mainContentContainerComputedStyle = window.getComputedStyle(mainContentContainer, null)
               const mainContentContainerPaddingY =  parseFloat(mainContentContainerComputedStyle.paddingLeft) + parseFloat(mainContentContainerComputedStyle.paddingRight);

               item.setAttribute('id','tableau-viz');
               item.setAttribute('src', identity.dashUrl);
               item.setAttribute('width', "100%");
               // @ts-ignore
               item.setAttribute('height', (mainContentContainer.clientHeight - mainContentContainerPaddingY).toString());
               item.setAttribute('toolbar', 'hidden');

               //@ts-ignore
               ref.current.appendChild(item);
           }
       }
    },[isLoading])

    // WHEN WINDOW GETS RESIZED CHANGE THE TABLEAU DASHBOARD STYLES
    window.addEventListener('resize', () => {
        const viz = document.getElementById('tableau-viz');
        // @ts-ignore
        const mainContentContainerComputedStyle = window.getComputedStyle(mainContentContainer, null)
        const mainContentContainerPaddingY =  parseFloat(mainContentContainerComputedStyle.paddingLeft) + parseFloat(mainContentContainerComputedStyle.paddingRight);

        // @ts-ignore
        viz.setAttribute('width', "100%");
        // @ts-ignore
        viz.setAttribute('height',  (mainContentContainer.clientHeight - mainContentContainerPaddingY).toString());
    });
    // @ts-ignore
    return (
        <div className="tableau-container" ref={ref}></div>
    );
}

