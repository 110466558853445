import React, {useContext, useEffect, useState} from 'react';
import {
    Datagrid,
    TextField,
    TextInput,
    PasswordInput,
    SimpleForm,
    Create,
    Edit,
    DateField,
    ShowButton,
    Show,
    SimpleShowLayout,
    SelectInput,
    List,
    required,
    Toolbar,
    SaveButton,
    ListButton,
    BooleanField,
    ReferenceField,
    FunctionField,
    ReferenceInput,
    AutocompleteInput,
    BooleanInput,
    minLength,
    TopToolbar,
    EditButton,
    useAuthProvider,
    useGetIdentity,
    useRedirect,
    useRefresh, AutocompleteArrayInput, ReferenceArrayInput, SingleFieldList, ChipField, ReferenceArrayField
} from 'react-admin';
import {FcLeft} from "react-icons/fc";
import {Button} from "@mui/material";
import FaceIcon from '@mui/icons-material/Face';
import {CompanyContext} from "../Context/CompanyContext";

const optionRenderer = (choice: any) => `#${choice.id} - ${choice.name}`;



const ListFilters = [
    <TextInput label="E-Mail" source="email" alwaysOn variant={"filled"}/>,
    <TextInput label="Vorname" source="firstname" alwaysOn variant={"filled"}/>,
    <TextInput label="Nachname" source="lastname" alwaysOn variant={"filled"}/>,
    <SelectInput source='isManufacturer' label="Hersteller" alwaysOn choices={[{id: 0, name: "Nein"},{id: 1, name: "Ja"}]} variant={"filled"}/>,
    <SelectInput source='isDealer' label="Händler" alwaysOn choices={[{id: 0, name: "Nein"},{id: 1, name: "Ja"}]} variant={"filled"}/>
];

export const UserList = (props: any) => {
    const authProvider = useAuthProvider();
    const redirect = useRedirect();
    const refresh = useRefresh()
    const {setActiveCompanyId} = useContext(CompanyContext)

    return (
        <List {...props} per perPage={25} filters={ListFilters} sx={{"& .filter-field > .MuiFormControl-root":{my:2}}} >
            <Datagrid rowClick="edit" bulkActionButtons={false}>
                <BooleanField source='isActive' label="Aktiv"/>
                <TextField source="email" label="Email" />
                <TextField source="firstname" label="Vorname"/>
                <TextField source="lastname" label="Nachname"/>
                <ReferenceField source="companyId" reference="companies" label={"Verknüpftes Unternehmen"} link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceArrayField source="manufacturerCompanies" reference="companies" label={"Hersteller Unternehmen"}>
                    <SingleFieldList linkType={false}>
                        <ChipField source="name"/>
                    </SingleFieldList>
                </ReferenceArrayField>
                <BooleanField source='isManufacturer' label="Hersteller"/>
                <BooleanField source='isDealer' label="Händler"/>
                <BooleanField source='betaUser' label="Beta User"/>
                <BooleanField source='newsletter' label="Newsletter"/>
                <FunctionField render={(record: any) => record.dashBoardUrl && <a target={"_blank"} href={record.dashBoardUrl}>Tableau Dashboard</a>} />
                <DateField source="createdAt" label="Erstellt am"/>
                <DateField source="lastLogin" label="Letzer Login"/>
                <ShowButton label={"Anzeigen"} />
                <FunctionField render={(rec: any) => {
                    if (rec.isManufacturer || rec.isDealer ) {
                        return(<Button id={"logginAs"} variant={"text"} startIcon={<FaceIcon/>} onClick={async (e) => {
                            e.stopPropagation();
                            const response = await authProvider.loginAsUser({loginUser: rec});
                            setActiveCompanyId(response.companyId)
                            redirect('/#/welcome');
                            refresh();
                        }}>
                            Einloggen
                        </Button>)
                    }
                    return null;

                }}/>

            </Datagrid>
        </List>
    );
};


const CustomToolbar = (props: any) => {
    return (
        <Toolbar {...props} >
            <SaveButton label={"Benutzer speichern"}/>
            <ListButton  label="Zurück zur Übersicht" icon={<FcLeft/>} style={{position:"absolute",right: 25}}/>
        </Toolbar>
    )
};
const filterToQuery = (searchText: string) => ({ name: `%${searchText}%` })
const UserSimpleForm = ({create = false}) => {
    const pwValidates = create ? [required(), minLength(8)] : [minLength(8)]

    const [isManufacturer, setIsManufacturer] = useState<boolean>(false);
    const [isDealer, setIsDealer] = useState<boolean>(false);

    const [companyRequired, setCompanyRequired] = useState<any>(<AutocompleteInput label={"Verknüpftes Unternehmen"} optionText={optionRenderer} fullWidth filterToQuery={filterToQuery} validate={undefined} />);

    useEffect(() => {
        if(isDealer || isManufacturer) {
            setCompanyRequired(<AutocompleteInput label={"Verknüpftes Unternehmen"} optionText={optionRenderer} fullWidth filterToQuery={filterToQuery} validate={required()} />);
        } else {
            setCompanyRequired(<AutocompleteInput label={"Verknüpftes Unternehmen"} optionText={optionRenderer} fullWidth filterToQuery={filterToQuery} validate={undefined} />);
        }
    }, [isManufacturer,isDealer]);

    return (
            <SimpleForm toolbar={<CustomToolbar/>} sx={{"& .MuiTextField-root":{width: 700}}} mode={"onBlur"} >
                <BooleanInput source="isActive"  label="Aktiviert" fullWidth/>
                <TextInput source="email" validate={[required()]}  label="Email" fullWidth/>
                <TextInput source="firstname" validate={[required()]}  label="Vorname" fullWidth/>
                <TextInput source="lastname"  label="Nachname" fullWidth/>
                <PasswordInput source="password" label="Neues Passwort" validate={pwValidates} fullWidth inputProps={{type: "new-password"}}/>
                <ReferenceInput source="companyId" reference="companies" sort={{ field: 'id', order: 'ASC' }} >
                    {companyRequired}
                </ReferenceInput>
                <ReferenceArrayInput source={'manufacturerCompanies'} reference={'companies'} >
                    <AutocompleteArrayInput
                        label={'Verknüpfte Hersteller Unternehmen'}
                        blurOnSelect={false}
                        disableCloseOnSelect
                        debounce={250}
                        optionText={optionRenderer}
                        filterToQuery={filterToQuery}
                        fullWidth
                    />
                </ReferenceArrayInput>
                <BooleanInput source="isManufacturer" label="Hersteller" onChange={(e) => {setIsManufacturer(e.target.checked)}}/>
                <BooleanInput source="isDealer" label="Händler" onChange={(e) => {setIsDealer(e.target.checked)}}/>
                <BooleanInput source='betaUser' label="Beta User"/>
                <TextInput source="dashBoardUrl" label="Dashboard Url"/>
            </SimpleForm>
    );
};

export const UserCreate = () => {
    return (
        <Create>
            <UserSimpleForm create={true}/>
        </Create>
    )
}

export const UserEdit = () => {
    return (
        <Edit>
            <UserSimpleForm/>
        </Edit>
    )
}

export const UserShow = (props: any) => {
    return (
        <Show actions={
            <TopToolbar>
                <EditButton/>
                <ListButton/>
            </TopToolbar>
        }>
            <SimpleShowLayout>
                <TextField source="email"></TextField>
                <TextField source="firstname"></TextField>
                <TextField source="lastname"></TextField>
                <TextField source="companyId"></TextField>
                <TextField source="isActive"></TextField>
                <TextField source="isManufacturer"></TextField>
                <TextField source="isDealer"></TextField>
                <TextField source="createdAt"></TextField>
            </SimpleShowLayout>
        </Show>
    )
}

export default {
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    options: { label: 'Users' },
    show: UserShow,
};