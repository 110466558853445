import React from "react";

export function welcome() {
return (
    <>
        <h1>Herzlich willkommen im Hersteller-Backend von Prosoom!</h1>
        <p>Hier im Backend kannst Du Deine Stammdaten hinterlegen. Hersteller-Daten, Marken, Produkte, Wettbewerber. Du erlangst damit viele Vorteile.</p>
        <ul>
            <li>
                Deine Produkte werden bei uns in der Suche besser gefunden. Generische Keywords kannst Du so direkt bei Deinen Produkten hinterlegen. Somit kannst Du die Nachfrage unmittelbar beeinflussen - und Deinen Umsatz steigern.*
            </li>
            <li>
                Von uns bekommst Du erste Auswertungen zu unserer Preissuchmaschine. Min- Max und Durchschnittspreise pro Artikel, für einzelne Marketplaces... Diese Auswertungen erhältst Du auch als Nicht-Prosoom-Kunde. Setzt voraus, dass die Produktdaten (PZN, Artikelname, GTIN, ASIN, Keywords) gepflegt sind.
            </li>
            <li>
                Als Prosoom-Kunde erhältst Du die verbesserten Stammdaten in Deinen Reports.
            </li>
        </ul>
        <p>Viel Spaß! <br></br>
            * Bitte stelle sicher, dass Deine gewählten Suchbegriffe juristisch haltbar sind.
        </p>
    </>
    );
}

export default {
    list: welcome,
    options: { label: 'Shops' },
};