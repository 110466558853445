import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Box,
    Typography,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions, TextField,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import PreviewIcon from '@mui/icons-material/Preview';
import UploadIcon from '@mui/icons-material/Upload';
import ImageOrPlaceholder from '../../utils/ImageOrPlaceholder';
import axios from 'axios';
import ErrorDialog from '../../utils/ErrorDialog'
import {CompanyContext} from "../Context/CompanyContext";
import {AutocompleteInput, ReferenceInput, SimpleForm, useGetIdentity} from "react-admin";

const ListBrandImage = (props: any) => {
    const {identity} = useGetIdentity()

    const [brand, setBrand] = useState({
        id: 0,
        brand: '',
        logo: '',
        s3LogoUrl: ''
    });
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dialogHeader, setDialogHeader] = useState('');
    const [imgUrl, setImgUrl] = useState<string>('');
    const [tempImgUrl, setTempImgUrl] = useState<string>('');
    const [dimensions, setDimensions] = useState<{ width: number, height: number } | null>(null);
    const [open, setOpen] = useState(false);

    const {activeCompanyId} = useContext(CompanyContext)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTempImgUrl(e.target.value);
    };

    const handleButtonClick = async () => {
        if(isValidUrl(tempImgUrl))
        {
            const dims = await getImageDimensions(tempImgUrl);
            setDimensions(dims);
            setImgUrl(tempImgUrl);
        }
        else
        {
            setErrorMessage('Bitte eine valide Bild URL eingeben.');
            setDialogHeader('Fehler');
            setErrorDialogOpen(true);
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleErrorDialogClose = () => {
        setErrorDialogOpen(false);
    };

    const getImageDimensions = (url: string): Promise<{ width: number, height: number }> => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve({ width: img.width, height: img.height });
            };
            img.onerror = (error) => {
                reject(error);
            };
            img.src = url;
        });
    };

    const handleUpload = () => {
        if(isValidUrl(imgUrl))
        {
            const result = {
                companyId: activeCompanyId,
                brandId: brand.id,
                imgUrl: imgUrl,
                width: dimensions?.width,
                height: dimensions?.height,
            }

            axios.post(process.env.REACT_APP_BACKEND_API+ '/brands/upload-image', result, {withCredentials: true})
                .then(response => {
                    setErrorMessage('Bild wurde erfolgreich hochgeladen.');
                    setDialogHeader('Erfolg');
                    setErrorDialogOpen(true);
                })
                .catch(error => {
                    setErrorMessage('Fehler beim image upload: '+ error);
                    setDialogHeader('Fehler');
                    setErrorDialogOpen(true);
                });
        }
        else {
            setErrorMessage('Bitte eine valide Bild URL eingeben.');
            setDialogHeader('Fehler');
            setErrorDialogOpen(true);
        }
    };

    const isValidUrl = (urlString: string): boolean => {
        try {
            // Wenn die URL nur mit 'www' startet, fügen wir 'http://' hinzu
            if (urlString.startsWith('www')) {
                urlString = 'http://' + urlString;
            }
            const url = new URL(urlString);
            return url.protocol === "http:" || url.protocol === "https:";
        } catch (_) {
            return false;
        }
    };

    const optionRenderer = (choice: any) => `${choice.brand}`;
    const filterToQuery = (searchText: any) => ({ brand: `%${searchText}%` });

    let primaryImage ='';
    if(brand?.s3LogoUrl) {
        primaryImage = brand?.s3LogoUrl;
    }else if(brand?.logo) {
        primaryImage = brand?.logo;
    }

    return (
        <>
            <Grid
                container
                direction="column"
                alignItems="center"
                spacing={3}
                sx={{ padding: 2 }}
            >
                <Grid item container alignItems="center" justifyContent="center" spacing={2}>
                    <Grid item>
                        <Typography variant="body1">Marke: </Typography>
                    </Grid>
                    <Grid item>
                        <SimpleForm toolbar={<></>}>
                            <ReferenceInput source={'brand_id'} reference={'brands'} filter={{'companyId:$eq': activeCompanyId }} >
                                <AutocompleteInput
                                    fullWidth
                                    filterToQuery={filterToQuery}
                                    optionText={optionRenderer}
                                    label="Verknüpfte Marke"
                                    variant="standard"
                                    onChange={(value,record: any) => {
                                        setBrand(record)
                                    }}
                                />
                            </ReferenceInput>
                        </SimpleForm>
                    </Grid>
                </Grid>

                <Grid item sx={{ width: '80%', textAlign: 'center' }}>
                    <Typography variant="h4">
                        {brand?.brand ? brand.brand : 'Leer'}
                    </Typography>
                </Grid>

                <Grid item container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6} container direction="column" alignItems="center">
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>Bisheriges Bild</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                            <ImageOrPlaceholder src={brand ? primaryImage : ''} height={500} alt="Img"/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} container direction="column" alignItems="center">
                        <Typography variant="body1" sx={{ textAlign: 'center' }}>Neues Bild</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                            <ImageOrPlaceholder src={imgUrl} height={500} alt="Img" />
                        </Box>
                    </Grid>
                </Grid>

                <Grid item container alignItems="center" justifyContent="center" spacing={2}>
                    <Grid item>
                        <Typography variant="body1">Neuer Bild URL: </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            label={'Bild URL'}
                            value={tempImgUrl}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<PreviewIcon />}
                            sx={{ padding: '8px 16px' }}
                            onClick={handleButtonClick}
                        >
                            Vorschau
                        </Button>
                    </Grid>
                </Grid>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{"Marken Logo Update Erklärung"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            In diesem Frontend können neue Markenbilder hochgeladen werden.
                            Zuerst wählt man die Marke aus.
                            Anschließend wird die Marke geladen und man kann unten die neue Bild Url eingeben.
                            Drückt man nun auf "Vorschau" wird rechts das neue Markenbild angezeigt.
                            Mit Upload läd man dieses nun noch und es sollte innerhalb kürzester Zeit in den
                            Stammdaten auftauchen.
                            Viel Spaß :)
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            Schließen
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid item container justifyContent="center" spacing={2} sx={{ width: '80%' }}>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<InfoIcon />}
                            sx={{ padding: '8px 16px' }}
                            onClick={handleClickOpen}
                        >
                            Hilfe
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            startIcon={<UploadIcon />}
                            sx={{ padding: '8px 16px' }}
                            onClick={handleUpload}
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>

                <ErrorDialog
                    open={errorDialogOpen}
                    onClose={handleErrorDialogClose}
                    errorMessage={errorMessage}
                    header={dialogHeader}
                />
            </Grid>
        </>
    )
}



export default {
    list: ListBrandImage,
    options: { label: 'Marken Image Upload' },
};