import * as React from 'react';
import {useContext, useState} from 'react';
import Box from '@mui/material/Box';
import LabelIcon from '@mui/icons-material/Label';
import {
    FcBookmark,
    FcFactory,
    FcPackage,
    FcHome,
    FcRegisteredTrademark,
    FcShop,
    FcSearch,
    FcPositiveDynamic,
    FcDownload,
    FcUpload,
    FcManager,
    FcSettings
} from "react-icons/fc";
import {
    MenuItemLink,
    MenuProps,
    useSidebarState, usePermissions, SimpleForm, ReferenceInput, AutocompleteInput, useGetIdentity,
} from 'react-admin';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SubMenu from './SubMenu';
import {CompanyContext} from "../components/Context/CompanyContext";

type MenuName = 'menuManufacturer' | 'menuDealer';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuManufacturer: true,
        menuDealer: true,
    });

    const {permissions,isLoading} = usePermissions();

    const {identity, isLoading: identityLoading } = useGetIdentity()

    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    if(isLoading) {
        return null;
    }

    const optionRenderer = (choice: any) => `${choice.name}`;
    const filterToQuery = (searchText: any) => ({ name: `%${searchText}%` });

    const CompanySwitcher = () => {
        const {activeCompanyId,setActiveCompanyId} = useContext(CompanyContext);

        const changeCompany = (value: any) => {
            if(value === '') {
                setActiveCompanyId(identity?.companyId);
            }else {
                setActiveCompanyId(value);
            }
        }

        let selectableCompanies = '[]';

        if(identity?.manufacturerCompanies && identity?.manufacturerCompanies.length > 0) {
            if(!identity?.manufacturerCompanies.includes(identity?.companyId)) {
                identity?.manufacturerCompanies.push(identity?.companyId)
            }
            selectableCompanies = identity?.manufacturerCompanies.toString()
        }

        if(activeCompanyId === 0) {
            setActiveCompanyId(identity?.companyId)
        }

        return (
            <SimpleForm toolbar={<></>}>
                <ReferenceInput source={'manufacturerCompanies'} reference={'companies'} filter={{'id:$in': selectableCompanies}}>
                    <AutocompleteInput helperText={false} size={'small'} label={'Unternehmen'} optionText={optionRenderer} onChange={changeCompany} filterToQuery={filterToQuery} defaultValue={activeCompanyId} />
                </ReferenceInput>
            </SimpleForm>
        )
    }

    return (
        <Box
            sx={{
                width: open ? 230 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <MenuItemLink
                to="/welcome"
                state={{ _scrollToTop: true }}
                primaryText='Willkommen'
                leftIcon={<DashboardIcon />}
                dense={dense}
            />
            <MenuItemLink
                to="/insights"
                state={{ _scrollToTop: true }}
                primaryText='Insights'
                leftIcon={<FcSearch/>}
                dense={dense}
            />
            {permissions.isAdmin || permissions.isManufacturer ?
                <SubMenu
                    handleToggle={() => handleToggle('menuManufacturer')}
                    isOpen={state.menuManufacturer}
                    name="Hersteller"
                    icon={<FcFactory/>}
                    dense={dense}
                >
                    <CompanySwitcher/>
                    <MenuItemLink
                        to="/companies"
                        state={{_scrollToTop: true}}
                        primaryText='Herstellerangaben'
                        leftIcon={<FcHome/>}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/brands"
                        state={{_scrollToTop: true}}
                        primaryText='Marken'
                        leftIcon={<FcRegisteredTrademark/>}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/brandsImageUpload"
                        state={{_scrollToTop: true}}
                        primaryText='Marken Bilder Upload'
                        leftIcon={<FcRegisteredTrademark/>}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/products"
                        state={{_scrollToTop: true}}
                        primaryText='Produkte'
                        leftIcon={<FcPackage/>}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/referrer"
                        state={{_scrollToTop: true}}
                        primaryText='Referrer'
                        leftIcon={<FcBookmark/>}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/downloads"
                        state={{_scrollToTop: true}}
                        primaryText='Downloads'
                        leftIcon={<FcDownload/>}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="https://www.prosoom.com/buy-box-generator"
                        state={{_scrollToTop: true}}
                        primaryText='BuyBox Generator'
                        leftIcon={<FcSettings/>}
                        dense={dense}
                        target={'_blank'}
                    />
                </SubMenu>: null
            }
            {permissions.isDealer || permissions.isAdmin ?
            <SubMenu
                handleToggle={() => handleToggle('menuDealer')}
                isOpen={state.menuDealer}
                name="Händler"
                icon={<LabelIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/base-data-dealer"
                    state={{_scrollToTop: true}}
                    primaryText='Händlerangaben'
                    leftIcon={<FcHome/>}
                    dense={dense}
                />
                <MenuItemLink
                    to="/dealer_products"
                    state={{ _scrollToTop: true }}
                    primaryText='Produkte'
                    leftIcon={<FcPackage/>}
                    dense={dense}
                />
                <MenuItemLink
                    to="/dealer_brands"
                    state={{ _scrollToTop: true }}
                    primaryText='Marken'
                    leftIcon={<FcRegisteredTrademark/>}
                    dense={dense}
                />
            </SubMenu> : null}
            {permissions.isAdmin ?
            <MenuItemLink
                to="/shops"
                state={{ _scrollToTop: true }}
                primaryText='Shops'
                leftIcon={<FcShop />}
                dense={dense}
            />: null}
            {permissions.isBetaUser && permissions.isDealer && <MenuItemLink
                to="/boerse"
                state={{_scrollToTop: true}}
                primaryText='Börse'
                leftIcon={<FcPositiveDynamic/>}
                dense={dense}
            />}
            {permissions.isAdmin ?
                <MenuItemLink
                    to="/iqvia_upload"
                    state={{ _scrollToTop: true }}
                    primaryText='Iqvia Upload'
                    leftIcon={<FcUpload />}
                    dense={dense}
                /> : null
            }
            {permissions.isAdmin ?
                <MenuItemLink
                    to="/users"
                    state={{ _scrollToTop: true }}
                    primaryText='Benutzer'
                    leftIcon={<FcManager />}
                    dense={dense}
                /> : null
            }
        </Box>
    );
};

/*
<MenuItemLink
                        to="/competitor"
                        state={{_scrollToTop: true}}
                        primaryText='Wettbewerber'
                        leftIcon={<FcConferenceCall/>}
                        dense={dense}
                    />
                    <MenuItemLink
                        to="/measures"
                        state={{_scrollToTop: true}}
                        primaryText='Kampagnen'
                        leftIcon={<FcViewDetails/>}
                        dense={dense}
                    />
 */


export default Menu;
