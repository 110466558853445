import React, {useContext, useEffect, useState} from 'react';
import {useGetIdentity, useListContext, useNotify, usePermissions, List} from 'react-admin';

import {DataGrid, GridSelectionModel} from "@mui/x-data-grid";
import Button from '@mui/material/Button';
import Stack from "@mui/material/Stack";
import {FcBookmark} from "react-icons/fc";
import {
    Card,
    TextField,
    Typography
} from "@mui/material";
import {CompanyContext} from "../Context/CompanyContext";

export function SheetJSReact() {
    const initalState = {
        id: 0,
        userId: '',
        referrer_url: '',
        last_updated: '',
        is_deleted: 0
    };

    const [rows, setRows] = useState<any[]>([]);

    const [user, setUser] = useState<any>();

    const [referrerData, setReferrerData] = useState<any>(initalState);

    const [rowsSelected, setRowsSelected] = useState<GridSelectionModel>([]);

    const notify = useNotify();

    const { identity, isLoading: identityLoading } = useGetIdentity();

    const {activeCompanyId} = useContext(CompanyContext)

    if(!identityLoading) {
        if(!user) {
            setUser({userId: identity?.id.toString(), companyId: identity?.companyId})
        }
    }

    const columns = [
        { field: "id", headerName: "Seiten Id", editable: false, minWidth: 150 },
        { field: "referrer_url", headerName: "Seiten Url", editable: false, minWidth: 800 },
        { field: "last_updated", headerName: "Letzte Änderungen", editable: false, minWidth: 150 },
    ];

    function createNewReferrerItems(newItems: any) {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/referrer/create-new-referrer', {
            method: 'POST',
            body: JSON.stringify({referrerData: newItems, companyId: activeCompanyId}),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });

        return fetch(request).then(
            (response) => {
                if(response.status !== 500) {
                    notify('Seiten wurden hochgeladen!');
                }

                loadData();
                return response.json();
            }
        );
    }

    function updateReferrerData(referrerData: any, isBulk: boolean = false) {
        let route = '/referrer/update-referrer';

        if(referrerData.delete !== undefined) {
            route = '/referrer/delete-referrer';
            referrerData = referrerData.rows;
        }

        const request = new Request(process.env.REACT_APP_BACKEND_API + route, {
            method: 'POST',
            body: JSON.stringify({referrerData: referrerData, companyId: activeCompanyId}),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });

        if(isBulk) {
            return fetch(request);
        }else {
            return fetch(request).then(
                (response) => {
                    if(response.status !== 500) {
                        notify('Seiten wurden hochgeladen!');
                    }

                    loadData();
                    return response.json();
                }
            );
        }
    }

    function loadData() {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/referrer/get-data', {
            method: 'POST',
            body: JSON.stringify({companyId: activeCompanyId}),
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        fetch(request).then(
            (response) => {
                return response.json();
            }
        ).then((referrers) => {
            let filteredReferrers = [];
            for (const referrer of referrers) {
                if (!referrer.last_updated) {
                    referrer.last_updated = new Date().toLocaleString('de-DE');
                } else {
                    referrer.last_updated = new Date(referrer.last_updated).toLocaleString('de-DE');
                }

                if (!referrer.is_deleted) {
                    filteredReferrers.push(referrer);
                }
            }

            setRows(filteredReferrers);

            if (referrers.length > 0) {
                notify('Seiten wurden geladen')
            } else {
                notify('Es wurden keine Seiten gefunden')
            }

            return referrers;
        });
    }

    const removeRow = () => {
        if(rowsSelected.length > 0) {
            let selectedItems: any[] = [];
            rowsSelected.forEach( (selectedId) => {
                rows.forEach((item,index) => {
                    if (item.id === selectedId) {
                        rows[index].is_deleted = 1;
                        selectedItems.push(rows[index]);
                    }
                });
            });

            // eslint-disable-next-line no-restricted-globals
            if(confirm('Ausgewählter Eintrage löschen ?')) {
                updateReferrerData({delete: true, rows: selectedItems})
            }
        }else {
            notify('Keine Seiten ausgewählt', {type: 'warning'})
        }
    }

    const handleAddConfirm = () => {
        referrerData.last_updated = new Date(Date.now()).toLocaleString('de-DE');

        if(isUrlValid(referrerData.referrer_url)) {
            createNewReferrerItems([referrerData]);
            setReferrerData(initalState);
        } else {
            notify('Ist die URL gültig ?', {type: 'warning'});
        }
    }

    function isUrlValid(str: string) {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', // fragment locator
            'i'
        );
        return pattern.test(str);
    }

    const Aside = () => {
        const {  isLoading } = useListContext();
        if (isLoading) return null;
        return (
            <Card sx={{ width: 400, margin: '3em 1em 1em 1em', padding: '1em' }}>
                <Typography variant="h6">Neue Referrer-URL anlegen</Typography>
                <TextField
                    defaultValue={referrerData.referrer_url}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="URL"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={(ev) => {referrerData.referrer_url = ev.target.value}}
                />
                <Button onClick={handleAddConfirm} variant={"contained"} sx={{float: "right", mt: 2}}>Hinzufügen</Button>
            </Card>
        );
    };

    useEffect(() => {
        loadData();
    }, [identityLoading,activeCompanyId])

    return (
        <>
            <h3><FcBookmark/> Seiten / Referrer</h3>
            <div style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', display: 'flex'}}>
                <div style={{width: '100%'}}>
                   <p>Dieser Tab dient dazu das Seiten/Referrer hochgeladen werden können, welche für den BuyButton/Searchbutton genutzt werden können.</p>
                </div>
            </div>
            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                <Button id="loadData" onClick={loadData}>Lade Daten</Button>

                <Button size="small" onClick={removeRow}>
                    Ausgewählter Datensatz Löschen
                </Button>
            </Stack>

            <List resource={undefined} empty={false} aside={<Aside />} exporter={false}>
                <DataGrid
                    autoHeight={true}
                    columns={columns}
                    rows={rows}
                    onSelectionModelChange={(ids) => {
                        setRowsSelected(ids);
                    }}
                />
            </List>
        </>
    )
}

export default {
    list: SheetJSReact,
    options: { label: 'Referrer' },
};