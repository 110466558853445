import React from 'react';
import TableauEmbedded from "./TableauDashboard";
import { FcSearch } from "react-icons/fc";


function list() {
    return (
        <>
            <h3><FcSearch/>Insight</h3>
            <p>
                Du hast Deine Daten für Prosoom gepflegt und hältst diese immer auf dem Laufenden?
                <br/> Dafür möchten wir uns bedanken.
                <br/>Du erhältst von uns diesen Bericht immer täglich aktualisiert. Weitere Daten - auch Realtime-Daten - kannst Du gerne bei Prosoom dazu buchen. Kontaktiere einfach William. <a href="mailto:william.ludwig-schulte@prosoom.com">william.ludwig-schulte@prosoom.com</a>
                <br/>Wir werden die Reports immer weiter ausbauen. Daher schau gerne immer wieder vorbei.
            </p>
            <TableauEmbedded/>
        </>

    );

}

export default {
    list: list,
    options: { label: 'Insights' },
};