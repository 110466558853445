import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Create,
    Datagrid,
    List,
    SaveButton,
    SimpleForm,
    TextInput,
    TextField,
    useGetIdentity,
    useNotify,
    usePermissions,
    Show,
    useRecordContext,
    TopToolbar,
    EditButton,
    ListButton,
    Pagination,
    SimpleShowLayout, Edit, ShowButton, TabbedForm, RichTextField, ReferenceInput, AutocompleteInput,
    FilterPayload, Toolbar, FilterForm, SearchInput, ChipField, FunctionField
} from 'react-admin';
import {DataGrid, GridRowModel, GridSelectionModel} from "@mui/x-data-grid";
import Stack from '@mui/material/Stack';
import * as XLSX from "xlsx";

import Button from '@mui/material/Button';
import {WorkBook} from "xlsx";
import {FcLeft, FcPackage} from "react-icons/fc";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputLabel,
    NativeSelect,
    Tabs,
    Tab
} from "@mui/material";
import {useFormContext} from "react-hook-form";
import Box from "@mui/material/Box";
import {
    ClearButtons, DefaultEditorOptions,
    FormatButtons,
    LevelSelect,
    LinkButtons,
    ListButtons,
    QuoteButtons,
    RichTextInput,
    RichTextInputToolbar
} from "ra-input-rich-text";
import {CompanyContext} from "../Context/CompanyContext";
import StarterKit from "@tiptap/starter-kit";


export function SheetJSReact() {
    const [rows, setRows] = useState<any[]>([]);

    const {activeCompanyId} = useContext(CompanyContext);

    const [user, setUser] = useState<any>();

    const [rowsSelected, setRowsSelected] = useState<GridSelectionModel>([]);

    const notify = useNotify();

    const {identity, isLoading: identityLoading} = useGetIdentity();

    if (!identityLoading) {
        if (!user) {
            setUser({userId: identity?.id.toString(), companyId: identity?.companyId})
        }
    }

    const columns = [
        {field: "brand", headerName: "Marke", editable: false, minWidth: 150},
        {field: "name", headerName: "Name", editable: false, minWidth: 200},
        {field: "itemId", headerName: "Produkt ID", editable: false, minWidth: 200},
        {field: "pzn_de", headerName: "PZN_DE", editable: false, minWidth: 150},
        {field: "pzn_at", headerName: "PZN_AT", editable: false, minWidth: 150},
        {field: "asin", headerName: "ASIN", editable: false, minWidth: 150},
        {field: "article_number", headerName: "Artikelnummer", editable: false, minWidth: 150},
        {field: "uvp", headerName: "UVP", editable: false, minWidth: 150},
        {field: "keywords", headerName: "Keywords", editable: false, minWidth: 150},
        {field: "gtin13", headerName: "GTIN_13", editable: false, minWidth: 150},
        {field: "gtin8", headerName: "GTIN_8", editable: false, minWidth: 150},
        {field: "isbn", headerName: "ISBN", editable: false, minWidth: 150},
        {field: "description", headerName: "Beschreibung", editable: false, minWidth: 150},
        {field: "product_url", headerName: "Produkt Url", editable: false, minWidth: 150},
        {field: "seo_description", headerName: "SEO Produkt Beschreibung", editable: false, minWidth: 150},
        {field: "document_url", headerName: "Dokument Url", editable: false, minWidth: 150},
        {field: "amountFirst", headerName: "Erste Menge", editable: false, minWidth: 150},
        {field: "unitFirst", headerName: "Erste Einheit", editable: false, minWidth: 150},
        {field: "amountSecond", headerName: "Zweite Menge", editable: false, minWidth: 150},
        {field: "unitSecond", headerName: "Zweite Einheit", editable: false, minWidth: 150},
        {field: "img_url", headerName: "Bild Url", editable: false, minWidth: 200},
        {field: "category_one", headerName: "Kategory Eins", editable: false, minWidth: 200},
        {field: "category_two", headerName: "Kategory Zwei", editable: false, minWidth: 200},
        {field: "last_updated", headerName: "Letzte Änderungen", editable: false, minWidth: 250},
        //{ field: "is_deleted", headerName: "Löschen", editable: false, minWidth: 150, type: 'boolean' },
    ];

    const inputRef = useRef(null);

    function setFileRows(input: any) {
        const file = input.target.files[0];
        const reader = new FileReader();

        // @ts-ignore
        reader.onload = (evt) => {
            // @ts-ignore
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type: "binary"});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);

            let fileRows = [];

            let toUpdateData = [];

            let id = 0;

            for (const line of data) {
                // @ts-ignore
                line.id = id;

                // @ts-ignore
                line.userId = user.userId;

                id++;

                // @ts-ignore
                if (line.is_deleted === undefined || line.is_deleted !== 1 || isNaN(line.is_deleted)) {
                    // @ts-ignore
                    line.is_deleted = 0;
                }

                // @ts-ignore
                if (!line.last_updated) {
                    // @ts-ignore
                    line.last_updated = new Date(Date.now()).toLocaleString('de-DE');
                }

                // @ts-ignore
                line.companyId = activeCompanyId;

                // @ts-ignore
                if (line.description) {
                    // @ts-ignore
                    line.description = line.description.substring(0, 1000);
                }

                // @ts-ignore
                if (line.seo_description) {
                    // @ts-ignore
                    line.seo_description = line.seo_description.substring(0, 50);
                }

                if (rows.some((entry) => {
                    // @ts-ignore
                    if (entry.name === line.name) {
                        // @ts-ignore
                        line.id = entry.id;
                    }
                    // @ts-ignore
                    return entry.name === line.name
                })) {
                    toUpdateData.push(line);

                    continue;
                }

                fileRows.push(line);
            }

            // eslint-disable-next-line no-restricted-globals
            if (confirm('Datei hochladen ?')) {
                if (fileRows.length > 0) {
                    createNewProductItems(fileRows);
                }

                if (toUpdateData.length > 0) {
                    for (const toUpdateDataItem of toUpdateData) {
                        updateProductData(toUpdateDataItem, true);
                    }
                }
            }
            // @ts-ignore
            inputRef.current.value = null;
        };
        if (file !== null) {
            reader.readAsBinaryString(file);
        }
    }

    function createNewProductItems(newItems: any) {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/products/create-new-products', {
            method: 'POST',
            body: JSON.stringify({companyId: activeCompanyId,productData: newItems}),
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        return fetch(request).then(
            (response) => {
                if (response.status !== 500) {
                    notify('Produkte wurden hochgeladen!');
                }

                loadData();
                return response.json();
            }
        );
    }

    function updateProductData(productData: any, isBulk: boolean = false) {
        let route = '/products/update-products';

        if (productData.delete !== undefined) {
            route = '/products/delete-products';
            productData = productData.rows;
        }

        const request = new Request(process.env.REACT_APP_BACKEND_API + route, {
            method: 'POST',
            body: JSON.stringify({companyId: activeCompanyId,productData: productData}),
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        if (isBulk) {
            fetch(request);
        } else {
            return fetch(request).then(
                (response) => {
                    if (response.status !== 500) {
                        notify('Produkte wurden hochgeladen!');
                    }

                    loadData();
                    return response.json();
                }
            );
        }
    }

    function loadData() {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/products/get-data', {
            method: 'POST',
            body: JSON.stringify({companyId: activeCompanyId}),
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        dataFetch(request);
    }

    const processRowUpdate = async (newRow: GridRowModel) => {
        const updatedRow = {...newRow};

        const updatedRows = rows.map((row) => {
            row = row.id === newRow.id ? updatedRow : row
            return row
        })

        setRows(updatedRows);
        return updatedRow;
    };

    const exportGrid = () => {
        let rowItems = rows;

        let data = [];

        if (rows.length === 0) {
            data.push({
                brand: '',
                name: '',
                pzn_de: '',
                pzn_at: '',
                asin: '',
                article_number: '',
                uvp: 0.0,
                keywords: '',
                gtin13: '',
                gtin8: '',
                isbn: '',
                description: '',
                product_url: '',
                seo_description: '',
                document_url: '',
                amountFirst: 0,
                unitFirst: '',
                amountSecond: 0,
                unitSecond: '',
                img_url: '',
                category_one: '',
                category_two: '',
                last_updated: '',
                //is_deleted: 0
            })
        }

        for (const item of rowItems) {
            data.push(
                {
                    brand: item.brand,
                    name: item.name,
                    pzn_de: item.pzn_de,
                    pzn_at: item.pzn_at,
                    asin: item.asin,
                    article_number: item.article_number,
                    uvp: item.uvp,
                    keywords: item.keywords,
                    gtin13: item.gtin13,
                    gtin8: item.gtin8,
                    isbn: item.isbn,
                    description: item.description,
                    product_url: item.product_url,
                    seo_description: item.seo_description,
                    document_url: item.document_url,
                    amountFirst: item.amountFirst,
                    unitFirst: item.unitFirst,
                    amountSecond: item.amountSecond,
                    unitSecond: item.unitSecond,
                    img_url: item.img_url,
                    category_one: item.category_one,
                    category_two: item.category_two,
                    last_updated: item.last_updated,
                    //is_deleted: item.is_deleted
                });
        }

        const ws = XLSX.utils.json_to_sheet(data);

        let wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, "Produkte");

        if (data.length !== 0) {
            XLSX.writeFile(wb as WorkBook, "Produkte.xlsx");
            notify(`Produkte wurden Exportiert!`, {type: 'success'});
        } else {
            notify(`Keine Produkte zum Exportieren!`, {type: 'error'});
        }

    }

    const removeRow = () => {
        if (rowsSelected.length > 0) {
            let selectedItems: any[] = [];
            rowsSelected.forEach((selectedId) => {
                rows.forEach((item, index) => {
                    if (item.id === selectedId) {
                        rows[index].is_deleted = 1;
                        selectedItems.push(rows[index]);
                    }
                });
            });

            // eslint-disable-next-line no-restricted-globals
            if (confirm('Ausgewählte Einträge löschen ?')) {
                updateProductData({delete: true, rows: selectedItems})
            }

        } else {
            notify('Keine Produkte ausgewählt', {type: 'warning'})
        }
    }

    const MyCustomNoRowsOverlay = () => (
        <></>
    );

    useEffect(() => {
        loadData();
    }, [identityLoading,activeCompanyId])

    function dataFetch(request: Request) {
        fetch(request).then(
            (response) => {
                return response.json();
            }
        ).then((products) => {
            let filteredProducts = [];
            for (const prod of products) {

                prod.companyId = activeCompanyId;

                if (!prod.last_updated) {
                    prod.last_updated = new Date().toLocaleString('de-DE');
                } else {
                    prod.last_updated = new Date(prod.last_updated).toLocaleString('de-DE');
                }
                if (!prod.is_deleted) {
                    filteredProducts.push(prod);
                }
            }

            setRows(filteredProducts);

            if (products.length > 0) {
                notify('Produkte wurden geladen')
            } else {
                notify('Es wurden keine Produkte gefunden')
            }
            return products;
        });
    }


    return (
        <>
            <input
                style={{display: "none"}}
                id="contained-button-file"
                type="file"
                ref={inputRef}
                onChange={setFileRows}
            />
            <InputLabel htmlFor="contained-button-file" style={{width: '100%'}}>
                <Button variant="contained" color="primary" component="span"
                        style={{marginBottom: '10px', marginTop: '10px', width: '100%'}}>
                    IMPORT DATA SHEET
                </Button>
            </InputLabel>
            <Stack direction="row" spacing={1} sx={{mb: 1}}>
                <NewProductDialog/>
                <Button id="exportData" onClick={exportGrid}>Data-Sheet herunterladen</Button>
                <Button id="loadData" onClick={loadData}>Lade Daten</Button>

                <Button size="small" onClick={removeRow}>
                    Ausgewählte Datensätze Löschen
                </Button>
            </Stack>

            <DataGrid
                autoHeight={true}
                pageSize={10}
                checkboxSelection
                columns={columns}
                rows={rows}
                processRowUpdate={processRowUpdate}
                components={{NoRowsOverlay: MyCustomNoRowsOverlay}}
                onProcessRowUpdateError={(error) => {
                    console.log(error)
                }}
                experimentalFeatures={{newEditingApi: true}}
                onSelectionModelChange={(ids) => {
                    setRowsSelected(ids);
                }}
            />
        </>
    )
}

const NewProductDialog = () => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const {activeCompanyId} = useContext(CompanyContext);

    const handleNewRowDialogOpen = () => {
        setOpenCreateDialog(true)
    };

    const handleNewRowDialogClose = () => {
        setOpenCreateDialog(false)
    };

    const CustomNewProductToolBar = (props: any) => {
        const onSuccess = (data:any ) => {
            setOpenCreateDialog(false);
        }


        return (
            <DialogActions>
                <Button onClick={handleNewRowDialogClose}>Abbrechen</Button>
                <SaveButton type="button" label={"Bestätigen"} mutationOptions={{onSuccess}}></SaveButton>
            </DialogActions>
        )
    }

    const {permissions} = usePermissions();
    const dataTransform = (data: any)  => ({...data,companyId: activeCompanyId});

    return (
        <>
            <Button onClick={handleNewRowDialogOpen} sx={{marginTop: "5px"}}>Neues Produkt anlegen</Button>
            <Create transform={dataTransform}>
                <Dialog open={openCreateDialog} onClose={handleNewRowDialogClose} maxWidth={'xl'}>
                    <DialogTitle>Anlegen eines neuen Produktes</DialogTitle>
                    <DialogContent>
                        <SimpleForm toolbar={<CustomNewProductToolBar/>}>
                            <Box sx={{display:'flex'}}>
                                <Box sx={{width: '100%', margin: '1%'}}>
                                    {permissions && permissions.isAdmin ?
                                        <TextInput
                                            name={'itemId'}
                                            source={'item_id'}
                                            label="Item ID"
                                            autoFocus
                                            margin="dense"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                        />
                                        :null
                                    }
                                    <ReferenceInput source={'brand_id'} reference={'brands'} >
                                        <AutocompleteInput
                                            fullWidth
                                            filterToQuery={filterToQuery}
                                            optionText={optionRenderer}
                                            label="Verknüpfte Marke"
                                            variant="standard"
                                        />
                                    </ReferenceInput>
                                    <TextInput
                                        source={"name"}
                                        required={true}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Name"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"pzn_de"}
                                        autoFocus
                                        margin="dense"
                                        id="pzn_de"
                                        label="PZN_DE"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"pzn_at"}
                                        autoFocus
                                        margin="dense"
                                        id="pzn_at"
                                        label="PZN_AT"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"asin"}
                                        autoFocus
                                        margin="dense"
                                        id="asin"
                                        label="ASIN"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        required={true}
                                        source={"article_number"}
                                        autoFocus
                                        margin="dense"
                                        id="article_number"
                                        label="Artikelnummer"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"uvp"}
                                        autoFocus
                                        margin="dense"
                                        id="uvp"
                                        label="UVP"
                                        type="number"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        required={true}
                                        source={"keywords"}
                                        autoFocus
                                        margin="dense"
                                        id="keywords"
                                        label="Keywords"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        helperText={(
                                            <span className={'char-counter'}>Komma (,) oder Pipe (|) separierte Liste</span>)}
                                    />
                                    <TextInput
                                        source={"gtin13"}
                                        autoFocus
                                        margin="dense"
                                        id="gtin13"
                                        label="GTIN_13"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"gtin8"}
                                        autoFocus
                                        margin="dense"
                                        id="gtin8"
                                        label="GTIN_8"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"isbn"}
                                        autoFocus
                                        margin="dense"
                                        id="isbn"
                                        label="ISBN"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />

                                </Box>
                                <Box sx={{width: '100%', margin: '1%'}}>
                                    <TextInput
                                        source={"product_url"}
                                        autoFocus
                                        margin="dense"
                                        id="productUrl"
                                        label="Produkt Url"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        helperText={(<span>Eine Verlinkung zu Ihrer Produktseite</span>)}
                                    />
                                    <TextInput
                                        source={"seo_description"}
                                        autoFocus
                                        margin="dense"
                                        id="seoText"
                                        label="SEO Beschreibung für Product Url"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        multiline={true}
                                        minRows={3}
                                        inputProps={{maxLength: 50}}
                                        helperText={(<span className={'char-counter'}>MAX: 50 Zeichen</span>)}
                                    />
                                    <TextInput
                                        source={"document_url"}
                                        autoFocus
                                        margin="dense"
                                        id="documentUrl"
                                        label="Dokument Url"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        helperText={(<span>Verlinkung zum Produktdatenblatt oder Packungsbeilage</span>)}
                                    />
                                    <TextInput
                                        source={"amountFirst"}
                                        autoFocus
                                        margin="dense"
                                        id="amountFirst"
                                        label="Erste Menge"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"unitFirst"}
                                        autoFocus
                                        margin="dense"
                                        id="unitFirst"
                                        label="Erste Einheit"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"amountSecond"}
                                        autoFocus
                                        margin="dense"
                                        id="amountSecond"
                                        label="Zweite Menge"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"unitSecond"}
                                        autoFocus
                                        margin="dense"
                                        id="unitSecond"
                                        label="Zweite Einheit"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"img_url"}
                                        autoFocus
                                        margin="dense"
                                        id="img_url"
                                        label="Bild Url"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"category_one"}
                                        autoFocus
                                        margin="dense"
                                        id="category_one"
                                        label="Kategorie Eins"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextInput
                                        source={"category_two"}
                                        autoFocus
                                        margin="dense"
                                        id="category_two"
                                        label="Kategorie Zwei"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                    />
                                </Box>
                                <Box sx={{width: '100%'}}>
                                    <RichTextInput
                                        source={"description"}
                                        toolbar={<RichTextInputToolbar><FormatButtons/><ListButtons/></RichTextInputToolbar>}
                                        autoFocus
                                        margin="dense"
                                        id="description"
                                        label="Beschreibung"
                                        type="text"
                                        fullWidth
                                        variant="standard"
                                        helperText={(<span className={'char-counter'}>MAX: 1000 Zeichen</span>)}
                                    />
                                </Box>
                            </Box>
                        </SimpleForm>
                    </DialogContent>
                </Dialog>
            </Create>
        </>
    )
}


const ProductsPagination = (props: any) => (
    <Pagination rowsPerPageOptions={[10, 50, 100, 250, 500]} {...props} />
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function tabProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}


const ShowProductData = (props: any) => {
    const [value, setValue] = React.useState(0);

    const handleTabChanged = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <h3><FcPackage/> Produkte</h3>
            <Box sx={{bgcolor: '#004BBB'}}>
                <Tabs value={value}
                      onChange={handleTabChanged}
                      indicatorColor="secondary"
                      textColor="inherit"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                >
                    <Tab label="Meine Produkte" sx={{color: "white", fontWeight: "bold"}} {...tabProps(0)} />
                    <Tab label="Import/Export" sx={{color: "white", fontWeight: "bold"}} {...tabProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <ListProducts/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <div style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', display: 'flex'}}>
                    <div style={{width: '50%'}}>
                        <p>Zum Bearbeiten von Produkten, musst Du diese zuerst hochladen.<br/></p>
                        <ol type="1">
                            <li>Klicke auf "Data Sheet herunterladen"</li>
                            <li>Trage die Daten in die Muster-Excel ein</li>
                            <li>Klicke danach auf "Import Data Sheet"</li>
                        </ol>
                    </div>
                    <div style={{width: '50%'}}>
                        <p>
                            Du willst <b>nachträglich</b> Daten anpassen? Verfahre wieder wie in Schritt 1-4
                            beschrieben.<br/>
                            Du willst Daten <b>löschen</b>? Du kannst Datensätze markieren und mit Klick auf
                            "Ausgewählte
                            Datensätze löschen" diese entfernen.<br/>
                        </p>
                    </div>
                </div>
                <SheetJSReact/>
            </CustomTabPanel>
        </>
    )
}

const ListProducts = (props: any) => {
    const {activeCompanyId} = useContext(CompanyContext);

    const productFilters = [
        <SearchInput placeholder="Name" source="name"  alwaysOn/>,
        <SearchInput placeholder="Marke" source="brand" alwaysOn/>,
        <SearchInput placeholder="PZN DE" source="pzn_de" alwaysOn/>,
        <SearchInput placeholder="PZN AT" source="pzn_at" alwaysOn/>,
        <SearchInput placeholder="GTIN 13" source="gtin13" alwaysOn/>,
    ];

    const ListActions = () => {
        return (
            <Box sx={{px: 1, display: 'flex'}}>
                <NewProductDialog/>
            </Box>
        )
    }

    return (
        <>
            <List
                {...props}
                perPage={10}
                filterDefaultValues={{companyId: activeCompanyId, is_deleted: 0}}
                pagination={<ProductsPagination/>}
                empty={false}
                exporter={false}
                actions={<ListActions/>}
                filters={productFilters}
            >
                <Datagrid
                    rowClick='show'
                    bulkActionButtons={false}
                >
                    <TextField
                        source="brand"
                        label="Markenname"
                        sx={{maxWidth: '150px', display: 'block'}}
                    />
                    <TextField
                        source={"name"}
                        label="Name"
                        sx={{maxWidth: '150px', display: 'block'}}
                    />
                    <TextField
                        source={"pzn_de"}
                        label="PZN_DE"
                        sx={{maxWidth: '150px', display: 'block'}}
                    />
                    <TextField
                        source={"pzn_at"}
                        label="PZN_AT"
                        sx={{maxWidth: '150px', display: 'block'}}
                    />
                    <TextField
                        source={"asin"}
                        label="ASIN"
                        sx={{maxWidth: '150px', display: 'block'}}
                    />
                    <TextField
                        source={"article_number"}
                        label="Artikelnummer"
                        sx={{maxWidth: '150px', display: 'block'}}
                    />
                    <TextField
                        source={"uvp"}
                        label="UVP"
                        sx={{maxWidth: '100px', display: 'block'}}
                    />
                    <TextField
                        source={"keywords"}
                        label="Keywords"
                        sx={{display: 'inline-block', maxWidth: '20em', overflow: 'hidden', textOverflow: 'ellipsis'}}
                    />
                    <TextField
                        source={"gtin_13"}
                        label="GTIN_13"
                        sx={{maxWidth: '150px', display: 'block'}}
                    />
                    <TextField
                        source={"gtin_8"}
                        label="GTIN_8"
                        sx={{maxWidth: '150px', display: 'block'}}
                    />
                    <TextField
                        source={"isbn"}
                        label="ISBN"
                        sx={{maxWidth: '150px', display: 'block'}}
                    />
                    <TextField
                        source={"amountFirst"}
                        label="Erste Menge"
                        sx={{maxWidth: '100px', display: 'block'}}
                    />
                    <TextField
                        source={"unitFirst"}
                        label="Erste Einheit"
                        sx={{maxWidth: '100px', display: 'block'}}
                    />
                    <TextField
                        source={"amountSecond"}
                        label="Zweite Menge"
                        sx={{maxWidth: '100px', display: 'block'}}
                    />
                    <TextField
                        source={"unitSecond"}
                        label="Zweite Einheit"
                        sx={{maxWidth: '100px', display: 'block'}}
                    />
                    <TextField
                        source={"img_url"}
                        label="Bild Url"
                        sx={{maxWidth: '100px', display: 'block'}}
                    />
                    <TextField
                        source={"category_one"}
                        label="Kategorie Eins"
                        sx={{maxWidth: '150px', display: 'block'}}
                    />
                    <TextField
                        source={"category_two"}
                        label="Kategorie Zwei"
                        sx={{maxWidth: '150px', display: 'block'}}
                    />
                    <FunctionField label={'Zum Produkt'} render={
                        (row: any) =>
                        {
                            if(row.itemId)
                            {
                                return <a href={'https://www.prosoom.com/product/'+ row.itemId} target={'_blank'}><Button>Produkt</Button></a>
                            }
                            return null
                        }
                    }/>
                    <ShowButton label={"Anzeigen"} />
                    <EditButton label={"Bearbeiten"}/>
                </Datagrid>
            </List>
        </>
    )
}

const ProductShowAction = () => {
    return (
        <TopToolbar>
            <ListButton label="Zurück zur Übersicht" icon={<FcLeft/>}/>
            <EditButton label={"Bearbeiten"}/>
        </TopToolbar>
    );
};


const ProductShow = (props: any) => {
    return (
        <>
            <Show actions={<ProductShowAction/>}>
                <SimpleShowLayout>
                    <TextField
                        source="brand"
                        label="Markenname"
                    />
                    <TextField
                        source={"name"}
                        label="Name"
                    />
                    <TextField
                        source={"pzn_de"}
                        label="PZN_DE"
                    />
                    <TextField
                        source={"pzn_at"}
                        label="PZN_AT"
                    />
                    <TextField
                        source={"asin"}
                        label="ASIN"
                    />
                    <TextField
                        source={"article_number"}
                        label="Artikelnummer"
                    />
                    <TextField
                        source={"uvp"}
                        label="UVP"
                    />
                    <RichTextField
                        source={"keywords"}
                        label="Keywords"
                    />
                    <TextField
                        source={"gtin_13"}
                        label="GTIN_13"
                    />
                    <TextField
                        source={"gtin_8"}
                        label="GTIN_8"
                    />
                    <TextField
                        source={"isbn"}
                        label="ISBN"
                    />
                    <RichTextField
                        source={"description"}
                        label="Beschreibung"
                    />
                    <TextField
                        source={"product_url"}
                        label="Produkt Url"
                    />
                    <TextField
                        source={"seo_descricption"}
                        label="SEO Beschreibung für Product Url"
                    />
                    <TextField
                        source={"document_url"}
                        label="Dokument Url"
                    />
                    <TextField
                        source={"amountFirst"}
                        label="Erste Menge"
                    />
                    <TextField
                        source={"unitFirst"}
                        label="Erste Einheit"
                    />
                    <TextField
                        source={"amountSecond"}
                        label="Zweite Menge"
                    />
                    <TextField
                        source={"unitSecond"}
                        label="Zweite Einheit"
                    />
                    <TextField
                        source={"img_url"}
                        label="Bild Url"
                    />
                    <TextField
                        source={"category_one"}
                        label="Kategorie Eins"
                    />
                    <TextField
                        source={"category_two"}
                        label="Kategorie Zwei"
                    />
                </SimpleShowLayout>
            </Show>
        </>
    );
}

const ProductEditAction = () => {
    return (
        <TopToolbar>
            <ShowButton label="Zurück zum Produkt"/>
            <ListButton label="Zurück zur Übersicht" icon={<FcLeft/>}/>
        </TopToolbar>
    );
};

const filterToQuery = (searchText: string) => ({brand: `%${searchText}%`})
const optionRenderer = (choice: any) => `${choice.brand}`;

const ProductEditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton label={"Speichern"}/>
        </Toolbar>
    )
}
const ProductEditSingle = (props: any) => {
    const {permissions} = usePermissions();

    return (
        <>
            <Edit {...props} actions={<ProductEditAction/>}>
                <TabbedForm toolbar={<ProductEditToolbar/>}>
                    <TabbedForm.Tab label={"Produtkt Bearbeiten"}>
                        {permissions.isAdmin ?
                            <TextInput
                                name={'itemId'}
                                source={'item_id'}
                                label="Item ID"
                                autoFocus
                                margin="dense"
                                type="text"
                                fullWidth
                            />
                            :null
                        }
                        <ReferenceInput source={'brand_id'} reference={'brands'} filter={{}} >
                            <AutocompleteInput
                                fullWidth
                                filterToQuery={filterToQuery}
                                optionText={optionRenderer}
                                label="Verknüpfte Marke" />
                        </ReferenceInput>
                        <TextInput
                            fullWidth
                            source={"name"}
                            label="Name"
                        />
                        <TextInput
                            fullWidth
                            source={"pzn_de"}
                            label="PZN_DE"
                        />
                        <TextInput
                            fullWidth
                            source={"pzn_at"}
                            label="PZN_AT"
                        />
                        <TextInput
                            fullWidth
                            source={"asin"}
                            label="ASIN"
                        />
                        <TextInput
                            fullWidth
                            source={"article_number"}
                            label="Artikelnummer"
                        />
                        <TextInput
                            fullWidth
                            source={"uvp"}
                            label="UVP"
                        />
                        <TextInput
                            fullWidth
                            source={"keywords"}
                            label="Keywords"
                            helperText={<span>Bitte kommasepariert</span>}
                        />
                        <TextInput
                            fullWidth
                            source={"gtin_13"}
                            label="GTIN_13"
                        />
                        <TextInput
                            fullWidth
                            source={"gtin_8"}
                            label="GTIN_8"
                        />
                        <TextInput
                            fullWidth
                            source={"isbn"}
                            label="ISBN"
                        />
                        <RichTextInput
                            fullWidth
                            toolbar={<></>}
                            source={"description"}
                            label="Beschreibung"
                        />
                        <TextInput
                            fullWidth
                            source={"product_url"}
                            label="Produkt Url"
                        />
                        <TextInput
                            fullWidth
                            source={"seo_descricption"}
                            label="SEO Beschreibung für Product Url"
                        />
                        <TextInput
                            fullWidth
                            source={"document_url"}
                            label="Dokument Url"
                        />
                        <TextInput
                            fullWidth
                            source={"amountFirst"}
                            label="Erste Menge"
                        />
                        <TextInput
                            fullWidth
                            source={"unitFirst"}
                            label="Erste Einheit"
                        />
                        <TextInput
                            fullWidth
                            source={"amountSecond"}
                            label="Zweite Menge"
                        />
                        <TextInput
                            fullWidth
                            source={"unit_second"}
                            label="Zweite Einheit"
                        />
                        <TextInput
                            fullWidth
                            source={"img_url"}
                            label="Bild Url"
                        />
                        <TextInput
                            fullWidth
                            source={"category_one"}
                            label="Kategorie Eins"
                        />
                        <TextInput
                            fullWidth
                            source={"category_two"}
                            label="Kategorie Zwei"
                        />
                    </TabbedForm.Tab>
                </TabbedForm>
            </Edit>
        </>
    );
}


export default {
    edit: ProductEditSingle,
    show: ProductShow,
    list: ShowProductData,
    options: {label: 'Products'},
};