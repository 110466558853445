let userId: number = 0;
let email: string = ''
let userDashUrl: string = "";
let companyId: number = 0;
let manufacturerCompanies: any = [];

const authProvider = {
    //@ts-ignore
    login: async ({username, password}) => {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/auth/login', {
            method: 'POST',
            body: JSON.stringify({email:username,password}),
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(async ({jwt,userData}) => {

                if (userData.isAdmin && !sessionStorage.getItem('adminUserToken')) {
                    sessionStorage.setItem('adminUserToken',jwt);
                }

                userId = userData.id;
                userDashUrl = userData.dashBoardUrl;
                companyId = userData.companyId;
                email = userData.email;
                manufacturerCompanies = userData.manufacturerCompanies;

                return userData;
            });
    },

    logout: () => {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/auth/logout', {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });

        if(sessionStorage.getItem('adminUserToken')) {
            sessionStorage.removeItem('adminUserToken');
        }
        if (sessionStorage.getItem('adminChangedUser')) {
            sessionStorage.removeItem('adminChangedUser');
        }

        return fetch(request).then(() => '/login');
    },

    checkAuth: () => {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/auth/check-auth-jwt', {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });

        return fetch(request).then((response) => {
            return response.json();
        }).then(async ({isAuthenticatedUser, jwt}) => {
            if (!isAuthenticatedUser) {
                return Promise.reject();
            }

            if (isAuthenticatedUser.isAdmin && !sessionStorage.getItem('adminUserToken')) {
                sessionStorage.setItem('adminUserToken',jwt);
            }

            userId = isAuthenticatedUser.id;
            companyId =  await fetch(new Request(process.env.REACT_APP_BACKEND_API + '/users/check-company', {
                method: 'POST',
                body: JSON.stringify({id: isAuthenticatedUser.id}),
                headers: new Headers({'Content-Type': 'application/json'}),
                credentials: 'include',
            })).then((response) => {
                return response.json();
            }).then((data) => {
                return data.companyId;
            });

            userDashUrl = await fetch(new Request(process.env.REACT_APP_BACKEND_API + '/users/get-dashboard-url', {
                method: 'POST',
                body: JSON.stringify({id: isAuthenticatedUser.id}),
                headers: new Headers({'Content-Type': 'application/json'}),
                credentials: 'include',
            })).then((response) => {
                return response.json();
            }).then((data) => {
                return data.url;
            });

            return Promise.resolve();
        })
    },

    checkError: (error: { status: any; }) => {
        const status = error.status;
        console.log(error);
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        if(userId === 0) return Promise.reject();
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/users/permissions', {
            method: 'POST',
            //NEED A WAY TO GET THE CORRECT USER ID ... BUT FROM WHERE ?
            body: JSON.stringify({id:userId}),
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            }).then((permissions) => {
                return Promise.resolve(permissions);
            })
    },

    getIdentity: () => {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/auth/identity', {
            method: 'GET',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            }).then((identity) => {
            return Promise.resolve(identity);
        })
    },

    //@ts-ignore
    loginAsUser: async ({loginUser}) => {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/auth/login-as-user', {
            method: 'POST',
            body: JSON.stringify(loginUser),
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        return await fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(async ({jwt,userData}) => {
                userId = userData.id;
                userDashUrl = userData.dashBoardUrl;
                companyId = userData.companyId;
                email = userData.email;

                sessionStorage.setItem('adminChangedUser', '1');

                return userData;
            });
    },
    //@ts-ignore
    returnToAdminAccount: async (adminToken) => {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/auth/back-to-admin', {
            method: 'POST',
            body: JSON.stringify(adminToken),
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(async ({jwt,userData}) => {
                userId = userData.id;
                userDashUrl = userData.dashBoardUrl;
                companyId = userData.companyId;
                email = userData.email;

                sessionStorage.removeItem('adminChangedUser');

                return userData;
            });
    }

};

export default authProvider;