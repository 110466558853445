import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    AutocompleteArrayInput, ChipField,
    Create,
    Datagrid,
    Edit,
    EditButton, ImageField,
    List,
    ListButton,
    NotificationOptions,
    NotificationType,
    Pagination,
    ReferenceArrayField,
    ReferenceArrayInput,
    SaveButton, Show, ShowButton,
    SimpleForm, SimpleShowLayout, SingleFieldList, TabbedForm,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    useGetIdentity,
    useNotify, useRecordContext,
} from 'react-admin';
import {DataGrid, GridRowModel, GridSelectionModel} from "@mui/x-data-grid";
import * as XLSX from "xlsx";

import Button from '@mui/material/Button';
import {WorkBook} from "xlsx";
import Stack from "@mui/material/Stack";
import {FcLeft, FcRegisteredTrademark} from "react-icons/fc";
import {
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    Tab, Tabs,
} from "@mui/material";
import Box from "@mui/material/Box";
import {ColorField, ColorInput} from 'react-admin-color-picker';
import {CompanyContext} from "../Context/CompanyContext";

export function SheetJSReact() {
    const [rows, setRows] = useState<any[]>([]);

    const [user, setUser] = useState<any>();

    const [rowsSelected, setRowsSelected] = useState<GridSelectionModel>([]);

    const notify = useNotify();

    const {identity, isLoading: identityLoading} = useGetIdentity();

    const {activeCompanyId} = useContext(CompanyContext)

    if (!identityLoading) {
        if (!user) {
            setUser({userId: identity?.id.toString(), companyId: activeCompanyId})
        }
    }

    const columns = [
        {field: "id", headerName: "Marken Id (brandId)", editable: false, minWidth: 150},
        {field: "brand", headerName: "Marke", editable: false, minWidth: 150},
        {field: "landingpage", headerName: "Landingpage", editable: false, minWidth: 150},
        {field: "logo", headerName: "Logo", editable: false, minWidth: 150},
        {field: "buyButton", headerName: "BuyButton Download", editable: false, minWidth: 200},
        {field: "colorCode", headerName: "Farb Code", editable: false, minWidth: 150},
        {field: "prosoom_url", headerName: "Prosoom URL", editable: false, minWidth: 450},
        {field: "prio_shops", headerName: "Priorisierte Shops", editable: false, minWidth: 450},
        {field: "category_one", headerName: "Kategory Eins", editable: false, minWidth: 200},
        {field: "category_two", headerName: "Kategory Zwei", editable: false, minWidth: 200},
        {field: "last_updated", headerName: "Letzte Änderungen", editable: false, minWidth: 150},
        //{ field: "is_deleted", headerName: "Löschen", editable: false, minWidth: 150, type: 'boolean' },
    ];

    const inputRef = useRef(null);

    function setFileRows(input: any) {
        const file = input.target.files[0];
        const reader = new FileReader();

        // @ts-ignore
        reader.onload = (evt) => {
            // @ts-ignore
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, {type: "binary"});
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);

            let fileRows = [];

            let toUpdateData = [];

            let id = 0;

            for (const line of data) {
                //console.log(line)

                // @ts-ignore
                line.id = id;

                // @ts-ignore
                line.userId = identity?.id;

                id++;

                // @ts-ignore
                if (line.is_deleted === undefined || line.is_deleted !== 1 || isNaN(line.is_deleted)) {
                    // @ts-ignore
                    line.is_deleted = 0;
                }

                // @ts-ignore
                if (!line.last_updated) {
                    // @ts-ignore
                    line.last_updated = new Date(Date.now()).toLocaleString('de-DE');
                }

                // @ts-ignore
                line.companyId = activeCompanyId;

                // @ts-ignore
                if (rows.some((entry) => {
                    // @ts-ignore
                    if (entry.brand === line.brand) {
                        // @ts-ignore
                        line.id = entry.id;
                    }
                    // @ts-ignore
                    return entry.brand === line.brand
                })) {
                    toUpdateData.push(line);

                    continue;
                }

                fileRows.push(line);
            }
            // eslint-disable-next-line no-restricted-globals
            if (confirm('Datei hochladen ?')) {
                if (fileRows.length > 0) {
                    createNewBrandItems(fileRows);
                }

                if (toUpdateData.length > 0) {
                    for (const toUpdateDataItem of toUpdateData) {
                        updateBrandData(toUpdateDataItem, true);
                    }
                }

            }
            // @ts-ignore
            inputRef.current.value = null;
        };

        if (file !== null) {
            reader.readAsBinaryString(file);
        }
    }

    function createNewBrandItems(newItems: any) {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/brands/create-new-brands', {
            method: 'POST',
            body: JSON.stringify({companyId: activeCompanyId, brandData: newItems}),
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        return fetch(request).then(
            (response) => {
                if (response.status !== 500) {
                    notify('Marken wurden hochgeladen!');
                }

                loadData(user, activeCompanyId, setRows, notify);
                return response.json();
            }
        );
    }


    function updateBrandData(brandData: any, isBulk: boolean = false) {
        let route = '/brands/update-brands';

        if (brandData.delete !== undefined) {
            route = '/brands/delete-brands';
            brandData = brandData.rows;
        }

        const request = new Request(process.env.REACT_APP_BACKEND_API + route, {
            method: 'POST',
            body: JSON.stringify({companyId: activeCompanyId, brandData: brandData}),
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        });

        if (isBulk) {
            fetch(request);
        } else {
            return fetch(request).then(
                (response) => {
                    if (response.status !== 500) {
                        notify('Marken wurden hochgeladen!');
                    }

                    loadData(user,activeCompanyId,setRows,notify);
                    return response.json();
                }
            );
        }
    }

    const processRowUpdate = async (newRow: GridRowModel) => {
        if (newRow.name === "") {
            throw new Error("name is empty");
        }
        const updatedRow = {...newRow};

        const updatedRows = rows.map((row) => {
            row = row.id === newRow.id ? updatedRow : row
            return row
        })

        setRows(updatedRows);
        return updatedRow;
    };

    const exportGrid = () => {
        let rowItems = rows;

        let data = [];

        if (rows.length === 0) {
            data.push({
                brand: '',
                landingpage: '',
                logo: '',
                buyButton: '',
                colorCode: '',
                //prosoom_url: '',
                category_one: '',
                category_two: '',
                last_updated: '',
                //is_deleted: 0
            })
        }

        for (const item of rowItems) {
            data.push(
                {
                    brand: item.brand,
                    landingpage: item.landingpage,
                    logo: item.logo,
                    buyButton: item.buyButton,
                    colorCode: item.colorCode,
                    //prosoom_url: item.prosoom_url,
                    category_one: item.category_one,
                    category_two: item.category_two,
                    last_updated: item.last_updated,
                    //is_deleted: item.is_deleted
                });
        }

        const ws = XLSX.utils.json_to_sheet(data);

        let wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, "Marken");

        if (data.length !== 0) {
            XLSX.writeFile(wb as WorkBook, "Marken.xlsx");
            notify(`Marken wurden Exportiert!`, {type: 'success'});
        } else {
            notify(`Keine Marken zum Exportieren!`, {type: 'error'});
        }
    }


    const removeRow = () => {
        if (rowsSelected.length > 0) {
            let selectedItems: any[] = [];
            rowsSelected.forEach((selectedId) => {
                rows.forEach((item, index) => {
                    if (item.id === selectedId) {
                        rows[index].is_deleted = 1;
                        selectedItems.push(rows[index]);
                    }
                });
            });

            // eslint-disable-next-line no-restricted-globals
            if (confirm('Ausgewählte Einträge löschen ?')) {
                updateBrandData({delete: true, rows: selectedItems})
            }

        } else {
            notify('Keine Produkte ausgewählt', {type: 'warning'})
        }
    }

    const triggerLoad = () => {
        loadData(user,activeCompanyId,setRows,notify);
    }

    useEffect(() => {
        triggerLoad();
    }, [activeCompanyId]);

    return (
        <>
            <input
                style={{display: "none"}}
                id="contained-button-file"
                type="file"
                ref={inputRef}
                onChange={setFileRows}
            />
            <InputLabel htmlFor="contained-button-file" style={{width: '100%'}}>
                <Button variant="contained" color="primary" component="span"
                        style={{marginBottom: '10px', marginTop: '10px', width: '100%'}}>
                    IMPORT DATA SHEET
                </Button>
            </InputLabel>
            <Stack direction="row" spacing={1} sx={{mb: 1}}>
                <CreateNewBrand/>
                <Button id="exportData" onClick={exportGrid}>Data-Sheet herunterladen</Button>
                <Button id="loadData" onClick={triggerLoad}>Lade Daten</Button>

                <Button size="small" onClick={removeRow}>
                    Ausgewählte Datensätze Löschen
                </Button>
            </Stack>

            <DataGrid
                autoHeight={true}
                rowsPerPageOptions={[10]}
                checkboxSelection
                columns={columns}
                rows={rows}
                processRowUpdate={processRowUpdate}
                onProcessRowUpdateError={(error) => {
                    console.log(error)
                }}
                experimentalFeatures={{newEditingApi: true}}
                onSelectionModelChange={(ids) => {
                    setRowsSelected(ids);
                }}
            />
        </>
    )
}


function loadData(user: { userId: any; companyId: any; }, activeCompanyId: number , setRows: {(value: React.SetStateAction<any[]>): void; (arg0: any[]): void;}, notify: { (message: React.ReactNode, options?: (NotificationOptions & { type?: NotificationType; })): void; (arg0: string): void; }) {
    const request = new Request(process.env.REACT_APP_BACKEND_API + '/brands/get-data', {
        method: 'POST',
        body: JSON.stringify({companyId: activeCompanyId, userId: user.userId}),
        headers: new Headers({'Content-Type': 'application/json'}),
        credentials: 'include',
    });

    fetch(request).then(
        (response) => {
            return response.json();
        }
    ).then((brands) => {
        let filteredBrands = [];
        for (const brand of brands) {
            if (!brand.last_updated) {
                brand.last_updated = new Date().toLocaleString('de-DE');
            } else {
                brand.last_updated = new Date(brand.last_updated).toLocaleString('de-DE');
            }

            brand.companyId = user.companyId;

            if(brand.colorCode !== "" && brand.colorCode !== null) {
                brand.prosoom_url = "https://www.prosoom.com/product/[PRODUCTID]/?brandId="+brand.id;

                brand.brand_id = brand.id;
            }

            if (!brand.is_deleted) {
                filteredBrands.push(brand);
            }
        }

        setRows(filteredBrands);

        if (brands.length > 0) {
            notify('Marken wurden geladen')
        } else {
            notify('Es wurden keine Marken gefunden')
        }

        return brands;
    });

}

const BrandsPagination = (props: any) => (
    <Pagination rowsPerPageOptions={[10, 50, 100, 250, 500]} {...props} />
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function tabProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}


const ShowBrandData = (props: any) => {
    const [value, setValue] = React.useState(0);

    const handleTabChanged = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <h3><FcRegisteredTrademark/> Marken</h3>
            <Box sx={{bgcolor: '#004BBB'}}>
                <Tabs value={value}
                      onChange={handleTabChanged}
                      indicatorColor="secondary"
                      textColor="inherit"
                      variant="fullWidth"
                      aria-label="full width tabs example"
                >
                    <Tab label="Meine Marken" sx={{color: "white", fontWeight: "bold"}} {...tabProps(0)} />
                    <Tab label="Import/Export" sx={{color: "white", fontWeight: "bold"}} {...tabProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <ListBrands/>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <div style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', display: 'flex'}}>
                    <div style={{width: '50%'}}>
                        <p>Zum Bearbeiten von Marken, musst Du diese zuerst hochladen.<br/></p>
                        <ol type="1">
                            <li>Klicke auf "Data Sheet herunterladen"</li>
                            <li>Trage die Daten in die Muster-Excel ein</li>
                            <li>Klicke danach auf "Import Data Sheet"</li>
                        </ol>
                    </div>
                    <div style={{width: '50%'}}>
                        <p>
                            Du willst <b>nachträglich</b> Daten anpassen? Verfahre wieder wie in Schritt 1-3
                            beschrieben.<br/>
                            Du willst Daten <b>löschen</b>? Du kannst Datensätze markieren und mit Klick auf
                            "Ausgewählte Datensätze löschen" diese entfernen.
                        </p>
                    </div>
                </div>
                <SheetJSReact/>
            </CustomTabPanel>
        </>
    )
}

const ListBrands = (props: any) => {
    const brandFilters = [
        <TextInput label="Markenname" source="brand" alwaysOn/>,
    ];

    const ListActions = () => {
        return (
            <Box sx={{px: 1, display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                <CreateNewBrand/>
            </Box>

        )
    }

    const {activeCompanyId} = useContext(CompanyContext);

    return (
        <>
            <List
                {...props}
                perPage={10}
                filterDefaultValues={{companyId: activeCompanyId, is_deleted: 0}}
                pagination={<BrandsPagination/>}
                empty={false}
                exporter={false}
                actions={<ListActions/>}
                filters={brandFilters}
            >
                <Datagrid
                    rowClick='show'
                    bulkActionButtons={false}
                >
                    <TextField source={'id'} label={"Marken Id (brandId)"} sx={{maxWidth: '150px', display: 'block'}}/>
                    <TextField source={'brand'} label={"Markenname"} sx={{maxWidth: '150px', display: 'block'}}/>
                    <ColorField source={'colorCode'} label={"Farb Code"} />
                    <TextField source={'category_one'} label={"Kategorie eins"}  sx={{maxWidth: '150px', display: 'block'}}/>
                    <TextField source={'category_two'} label={"Kategorie zwei"} sx={{maxWidth: '150px', display: 'block'}}/>
                    <ReferenceArrayField source="prio_shops" reference="prio-shops" label={"Priorisierte Shops"}>
                        <SingleFieldList linkType={false}>
                            <ChipField source="shopUrl"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <ShowButton label={"Anzeigen"} />
                    <EditButton label={"Bearbeiten"}/>
                </Datagrid>
            </List>

        </>
    )
}

const CreateNewBrand = () => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const {activeCompanyId} = useContext(CompanyContext);

    let prioShopsLength = 0;

    const handleNewRowDialogOpen = () => {
        setOpenCreateDialog(true)
    };

    const handleNewRowDialogClose = () => {
        setOpenCreateDialog(false)
    };

    const CustomNewBrandToolBar = (props: any) => {
        const onSuccess = (data:any ) => {
            setOpenCreateDialog(false);
        }

        return (
            <DialogActions>
                <Button onClick={handleNewRowDialogClose}>Abbrechen</Button>
                <SaveButton type="button" label={"Bestätigen"} mutationOptions={{onSuccess}}></SaveButton>
            </DialogActions>
        )
    }

    const checkPrioShopLength = () => {
        if(prioShopsLength === 3) {
            return true;
        }
        return false;
    }

    const dataTransform = (data: any)  => ({...data,companyId: activeCompanyId});

    return (
        <>
            <Button onClick={handleNewRowDialogOpen} sx={{marginTop: "5px"}}>Neue Marke anlegen</Button>
            <Create transform={dataTransform}>
                <Dialog open={openCreateDialog} onClose={handleNewRowDialogClose} fullWidth>
                    <DialogTitle>Anlegen einer neuen Marke </DialogTitle>
                    <DialogContent>
                        <SimpleForm toolbar={<CustomNewBrandToolBar/>}>
                            <TextInput source={'brand'} label={"Markenname"} fullWidth/>
                            <TextInput source={'landingpage'} label={"Marken Webseite"} fullWidth/>
                            <TextInput source={'logo'} label={"Logo Url"} fullWidth/>
                            <ColorInput source={'colorCode'} variant={'outlined'} label={"Farb Code"} helperText={"Dieser Farbcode dient dazu, Ihre Hauptfarbe für unsere gebrandet Suche einzustellen"}/>
                            <TextInput source={'category_one'} label={"Kategorie eins"} fullWidth/>
                            <TextInput source={'category_two'} label={"Kategorie zwei"} fullWidth/>
                            <ReferenceArrayInput source="prio_shops" reference="prio-shops" sort={{ field: 'id', order: 'ASC' }} >
                                <AutocompleteArrayInput
                                    suggestionLimit={5}
                                    blurOnSelect={false}
                                    disableCloseOnSelect
                                    label={"Empfohlene Händler"}
                                    debounce={250}
                                    getOptionDisabled={checkPrioShopLength}
                                    onChange={(e) => { prioShopsLength = e.length}}
                                    optionText={optionRenderer}
                                    fullWidth
                                    filterToQuery={filterToQuery}
                                    helperText={(<span>Suchen und wählen Sie bis zu drei Empfohlene Händler aus. Die Reihenfolge entspricht dem Ranking in der Produktsuche</span>)}
                                />
                            </ReferenceArrayInput>
                        </SimpleForm>
                    </DialogContent>
                </Dialog>
            </Create>
        </>
    )
}

const BrandShowAction = () => {
    return (
        <TopToolbar>
            <ListButton label="Zurück zur Übersicht" icon={<FcLeft/>}/>
            <EditButton label={"Bearbeiten"}/>
        </TopToolbar>
    );
};

const LogoImageOrS3Image = () => {
    const record = useRecordContext();

    if(record.s3LogoUrl !== null) {
        return (
            <ImageField source={'s3LogoUrl'}/>
        )
    }

    return (
        <ImageField source={'logo'}/>
    )
}

const BrandShow = (props: any) => {
    return (
        <>
            <Show actions={<BrandShowAction/>}>
                <SimpleShowLayout>
                    <TextField source={'id'} label={"Marken Id (brandId)"} />
                    <TextField source={'brand'} label={"Markenname"} />
                    <TextField source={'landingpage'} label={"Marken Webseite"} />
                    <LogoImageOrS3Image />
                    <ColorField source={'colorCode'} label={"Farb Code"} />
                    <TextField source={'category_one'} label={"Kategorie eins"} />
                    <TextField source={'category_two'} label={"Kategorie zwei"} />
                    <ReferenceArrayField source="prio_shops" reference="prio-shops" label={"Priorisierte Shops"}>
                        <SingleFieldList linkType={false}>
                            <ChipField source="shopUrl" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                </SimpleShowLayout>
            </Show>
        </>
    );
}

const BrandEditAction = () => {
    return (
        <TopToolbar>
            <ShowButton label="Zurück zur Marke"/>
            <ListButton label="Zurück zur Übersicht" icon={<FcLeft/>}/>
        </TopToolbar>
    );
};

const filterToQuery = (searchText: string) => ({shopUrl: `%${searchText}%`})
const optionRenderer = (choice: any) => `${choice.shopUrl}`;

const BrandEditToolBar = () => {
    return (
        <Toolbar>
            <SaveButton label={"Speichern"}/>
        </Toolbar>
    )
}

const BrandEditSingle = (props: any) => {

    let prioShopsLength = 0;
    const checkDisabled = () => {
        if(prioShopsLength === 3) return true;
        return false;
    }

    return (
        <>
            <Edit {...props} actions={<BrandEditAction/>}>
                <TabbedForm toolbar={<BrandEditToolBar/>}>
                    <TabbedForm.Tab label={"Marke Bearbeiten"}>
                        <TextInput source={'brand'} label={"Marke"} fullWidth/>
                        <TextInput source={'landingpage'} label={"Marken Webseite"} fullWidth/>
                        <TextInput source={'logo'} fullWidth/>

                        <ColorInput source={'colorCode'} label={"Farb Code"} variant={"outlined"} helperText={"Dieser Farbcode dient dazu, Ihre Hauptfarbe für unsere gebrandet Suche einzustellen"}/>

                        <TextInput source={'category_one'} label={"Kategorie Eins"} fullWidth/>
                        <TextInput source={'category_two'} label={"Kategorie Zwei"} fullWidth/>
                        <ReferenceArrayInput source="prio_shops" reference="prio-shops"
                                             sort={{field: 'id', order: 'ASC'}}>
                            <AutocompleteArrayInput
                                suggestionLimit={5}
                                blurOnSelect={false}
                                disableCloseOnSelect
                                getOptionDisabled={checkDisabled}
                                onChange={(e) => {prioShopsLength = e.length}}
                                label={"Empfohlene Händler"}
                                debounce={250}
                                optionText={optionRenderer}
                                fullWidth
                                filterToQuery={filterToQuery}
                                helperText={(
                                    <span>Suchen und wählen Sie bis zu drei Empfohlene Händler aus. Die Reihenfolge entspricht dem Ranking in der Produktsuche</span>)}
                            />
                        </ReferenceArrayInput>
                    </TabbedForm.Tab>
                </TabbedForm>
            </Edit>
        </>
    );
}


export default {
    edit: BrandEditSingle,
    show: BrandShow,
    list: ShowBrandData,
    options: { label: 'Marken' },
};