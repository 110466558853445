import React, {useRef} from 'react';
import {FcPackage} from "react-icons/fc";
import Button from "@mui/material/Button";
import {InputLabel} from "@mui/material";

export function IqviaUpload() {
    const inputRef = useRef(null);
    function dataImport(data: any) {
        const reader = new FileReader();

        reader.onload = async () => {
            // @ts-ignore
            let allItems = reader.result.split("\n");

            //let headers = allItems[0].split(",");

            let entryRows = [];

            for(const [index,row] of allItems.entries()) {
                if(index === 0) continue;

                const rowData = row.split(',');

                entryRows.push(rowData);

                if(entryRows.length === 100000) {
                    await batchSender(entryRows);

                    console.log("Batch send");

                    entryRows = [];
                }
            }

            if(entryRows.length !== 1) {
                await batchSender(entryRows);

                console.log("Final Batch send");

                entryRows = [];
            }
        };

        if(data.target.files.length > 0) {
            reader.readAsText(data.target.files[0]);
        }

        // @ts-ignore
        inputRef.current.value = null;
    }

    async function batchSender(batchData: any) {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/iqvia/iqvia-sync-data', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(batchData),
            credentials: 'include',
        });

        await fetch(request).then(
            (response) => {
                if(response.status !== 500) {
                    console.log('Daten wurden hochgeladen!');
                }

                return response;
                //return response.json();
            }
        );
    }

    return (
        <>
            <h3><FcPackage/> Iqvia Stammdaten Upload</h3>
            <div style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', display: 'flex'}}>
                <div style={{width: '50%'}}>
                    <p>Hier bitte die IQVIA Stammdatendatei hochladen.<br/></p>
                </div>
            </div>
            <div style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', display: 'flex'}}>
                <div style={{ width: "100%"}}>
                    <h3>Data Upload</h3>
                </div>
                <div style={{ width: "50%"}}>
                    <input
                        style={{ display: "none" }}
                        id="contained-button-file"
                        type="file"
                        ref={inputRef}
                        onChange={dataImport}
                    />
                    <InputLabel htmlFor="contained-button-file" style={{width: '100%'}}>
                        <Button variant="contained" color="primary" component="span" style={{marginBottom: '10px', marginTop: '10px', width: '100%'}}>
                            IMPORT IQVIA DATA
                        </Button>
                    </InputLabel>
                </div>
            </div>
        </>
    )
}

export default {
    list: IqviaUpload,
    options: { label: 'IqviaUpload' },
};