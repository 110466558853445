import React, {useEffect, useState} from 'react';
import {Confirm, SaveButton, SimpleForm, useGetIdentity, useNotify, usePermissions} from 'react-admin';
import { FileField, FileInput } from "react-admin";
import {DataGrid, GridRowModel, GridSelectionModel} from "@mui/x-data-grid";
import Stack from '@mui/material/Stack';
import * as XLSX from "xlsx";

import Button from '@mui/material/Button';
import {WorkBook} from "xlsx";
import {FcPackage} from "react-icons/fc";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputLabel,
    NativeSelect,
    TextField
} from "@mui/material";

export function SheetJSReact() {
    const initalState = {
        id: 0,
        userId: '',
        companyId: '',
        brand: '',
        name: '',
        pzn_de: '',
        pzn_at: '',
        asin: '',
        article_number: '',
        uvp: 0.0,
        keywords: '',
        gtin13: '',
        gtin8: '',
        isbn: '',
        amountFirst: 0,
        unitFirst: '',
        amountSecond: 0,
        unitSecond: '',
        img_url: '',
        category_one: '',
        category_two: '',
        last_updated: '',
        is_deleted: 0
    };

    const [rows, setRows] = useState<any[]>([]);

    const [users, setUsers] = useState<any[]>([]);
    const [user, setUser] = useState<any>();

    const [productData, setProductData] = useState<any>(initalState);

    const [openChangeModal, setOpenChangeModal] = useState(false);

    const [rowsSelected, setRowsSelected] = useState<GridSelectionModel>([]);

    const notify = useNotify();

    const { identity, isLoading: identityLoading } = useGetIdentity();

    const {permissions,isLoading} = usePermissions();

    if(!identityLoading) {
        if(!user) {
            setUser({userId: identity?.id.toString(), companyId: identity?.companyId})
        }
    }

    const columns = [
        { field: "edit", headerName: "Bearbeiten", editable: false, minWidth: 100, renderCell: (params: any ) => {
                return (<>
                    <Button
                        onClick={() => {
                            setProductData(params.row)
                            setOpenChangeModal(true)
                        }}
                        variant="contained"
                    >
                        Edit
                    </Button>
                </>)
            } },
        { field: "brand", headerName: "Marke", editable: false, minWidth: 150 },
        { field: "name", headerName: "Name", editable: false, minWidth: 200 },
        { field: "pzn_de", headerName: "PZN_DE", editable: false, minWidth: 150 },
        { field: "pzn_at", headerName: "PZN_AT", editable: false, minWidth: 150 },
        { field: "asin", headerName: "ASIN", editable: false, minWidth: 150 },
        { field: "article_number", headerName: "Artikelnummer", editable: false, minWidth: 150 },
        { field: "uvp", headerName: "UVP", editable: false, minWidth: 150 },
        { field: "keywords", headerName: "Keywords", editable: false, minWidth: 150 },
        { field: "gtin13", headerName: "GTIN_13", editable: false, minWidth: 150},
        { field: "gtin8", headerName: "GTIN_8", editable: false, minWidth: 150 },
        { field: "isbn", headerName: "ISBN", editable: false, minWidth: 150 },
        { field: "amountFirst", headerName: "Erste Menge", editable: false, minWidth: 150 },
        { field: "unitFirst", headerName: "Erste Einheit", editable: false, minWidth: 150 },
        { field: "amountSecond", headerName: "Zweite Menge", editable: false, minWidth: 150 },
        { field: "unitSecond", headerName: "Zweite Einheit", editable: false, minWidth: 150 },
        { field: "img_url", headerName: "Bild Url", editable: false, minWidth: 200 },
        { field: "category_one", headerName: "Kategory Eins", editable: false, minWidth: 200 },
        { field: "category_two", headerName: "Kategory Zwei", editable: false, minWidth: 200 },
        { field: "last_updated", headerName: "Letzte Änderungen", editable: false, minWidth: 250 },
        //{ field: "is_deleted", headerName: "Löschen", editable: false, minWidth: 150, type: 'boolean' },
    ];

    function setFileRows(input: any) {
        const file = input.target.files[0];
        const reader = new FileReader();

        // @ts-ignore
        reader.onload = (evt) => {
            // @ts-ignore
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);

            let fileRows = [];

            let updateRows = [];

            let id = 0;

            for (const line of data) {
                // @ts-ignore
                line.id = id;

                // @ts-ignore
                line.userId = user.userId;

                id++;

                // @ts-ignore
                if(line.is_deleted === undefined || line.is_deleted !== 1 || isNaN(line.is_deleted)) {
                    // @ts-ignore
                    line.is_deleted = 0;
                }

                // @ts-ignore
                if (!line.last_updated) {
                    // @ts-ignore
                    line.last_updated = new Date(Date.now()).toLocaleString('de-DE');
                }

                // @ts-ignore
                line.companyId = user.companyId;

                // @ts-ignore
                if (rows.some((entry) => entry.name === line.name)) {
                    continue;
                }

                fileRows.push(line);
            }


            // eslint-disable-next-line no-restricted-globals
            if(confirm('Datei hochladen ?')) {
                createNewProductItems(fileRows);

                setRows(fileRows);
            }

        };
    }

    function createNewProductItems(newItems: any) {
        let request = null
        if(!isLoading) {
            if(user.userId != identity?.id) {
                request = new Request(process.env.REACT_APP_BACKEND_API + '/products/create-new-products', {
                    method: 'POST',
                    body: JSON.stringify({userId: user.userId, productData: newItems}),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                    credentials: 'include',
                });
            } else {
                request = new Request(process.env.REACT_APP_BACKEND_API + '/products/create-new-products', {
                    method: 'POST',
                    body: JSON.stringify({productData: newItems}),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                    credentials: 'include',
                });
            }

            return fetch(request).then(
                (response) => {
                    if(response.status !== 500) {
                        notify('Produkte wurden hochgeladen!');
                    }

                    loadData();
                    return response.json();
                }
            );
        }
    }

    function updateProductData(productData: any) {
        let route = '/products/update-products';

        if(productData.delete !== undefined) {
            route = '/products/delete-products';
            productData = productData.rows;
        }

        let request = null
        if(!isLoading) {
            if(user.userId !== identity?.id) {
                request = new Request(process.env.REACT_APP_BACKEND_API + route, {
                    method: 'POST',
                    body: JSON.stringify({userId: user.userId, productData: productData}),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                    credentials: 'include',
                });
            } else {
                request = new Request(process.env.REACT_APP_BACKEND_API + route, {
                    method: 'POST',
                    body: JSON.stringify({productData: productData}),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                    credentials: 'include',
                });
            }

            return fetch(request).then(
                (response) => {
                    if(response.status !== 500) {
                        notify('Produkte wurden hochgeladen!');
                    }

                    loadData();
                    return response.json();
                }
            );
        }
    }

    function loadData() {
        let request = null;
        if(!isLoading) {
            if(user !== undefined && user.userId !== identity?.id) {
                request = new Request(process.env.REACT_APP_BACKEND_API + '/products/get-admin-data', {
                    method: 'POST',
                    body: JSON.stringify({userId: user.userId, companyId: user.companyId}),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                    credentials: 'include',
                });
            } else {
                request = new Request(process.env.REACT_APP_BACKEND_API + '/products/get-data', {
                    method: 'POST',
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                    credentials: 'include',
                });
            }

            dataFetch(request);
        }

    }

    const processRowUpdate = async (newRow: GridRowModel) => {
        const updatedRow = { ...newRow };

        const updatedRows = rows.map((row) => {
            row = row.id === newRow.id ? updatedRow : row
            return row
        })

        setRows(updatedRows);
        return updatedRow;
    };

    const exportGrid = () => {
        let rowItems = rows;

        let data = [];

        if(rows.length === 0) {
            data.push({
                brand: '',
                name: '',
                pzn_de: '',
                pzn_at: '',
                asin: '',
                article_number: '',
                uvp: 0.0,
                keywords: '',
                gtin13: '',
                gtin8: '',
                isbn: '',
                amountFirst: 0,
                unitFirst: '',
                amountSecond: 0,
                unitSecond: '',
                img_url: '',
                category_one: '',
                category_two: '',
                last_updated: '',
                //is_deleted: 0
            })
        }

        for (const item of rowItems){
            data.push(
                {
                    brand: item.brand,
                    name: item.name,
                    pzn_de: item.pzn_de,
                    pzn_at: item.pzn_at,
                    asin: item.asin,
                    article_number: item.article_number,
                    uvp: item.uvp,
                    keywords: item.keywords,
                    gtin13: item.gtin13,
                    gtin8: item.gtin8,
                    isbn: item.isbn,
                    amountFirst: item.amountFirst,
                    unitFirst: item.unitFirst,
                    amountSecond: item.amountSecond,
                    unitSecond: item.unitSecond,
                    img_url: item.img_url,
                    category_one: item.category_one,
                    category_two: item.category_two,
                    last_updated: item.last_updated,
                    //is_deleted: item.is_deleted
                });
        }

        const ws = XLSX.utils.json_to_sheet(data);

        let wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, "Produkte");

        if(data.length !== 0) {
            XLSX.writeFile(wb as WorkBook, "Produkte.xlsx");
            notify(`Produkte wurden Exportiert!`, { type: 'success' });
        }else {
            notify(`Keine Produkte zum Exportieren!`, { type: 'error' });
        }

    }

    const removeRow = () => {
        if(rowsSelected.length > 0) {
            let selectedItems: any[] = [];
            rowsSelected.forEach( (selectedId) => {
                rows.forEach((item,index) => {
                    if (item.id === selectedId) {
                        rows[index].is_deleted = 1;
                        selectedItems.push(rows[index]);
                    }
                });
            });

            // eslint-disable-next-line no-restricted-globals
            if(confirm('Ausgewählte Einträge löschen ?')) {
                updateProductData({delete: true, rows: selectedItems})
            }

        }else {
            notify('Keine Produkte ausgewählt', {type: 'warning'})
        }
    }

    async function getUsers() {
        const request = new Request(process.env.REACT_APP_BACKEND_API + '/users/get-admin-users', {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });

        const data = await fetch(request).then(
            (response) => {
                return response.json();
            }
        )
        setUsers(data);
    }

    const MyCustomNoRowsOverlay = () => (
            <></>
    );

    const handleAdminUserChange = (event: any) => {
        const user = users.find(elem => elem.user_id.toString() === event.target.value);

        setUser({userId: user.user_id.toString(), companyId: user.company_id})

    }

    useEffect(() => {
        if(!isLoading && permissions.isAdmin) {
            getUsers();
        }

        loadData();
    }, [user,isLoading])

    const handleChangeDialogClose = () => {
        setProductData(initalState);
        setOpenChangeModal(false)
    };

    const handleChangeModalConfirm = () => {
        productData.last_updated = new Date(Date.now()).toLocaleString('de-DE');

        let fileRows = [...rows];

        for (const data of fileRows) {
            const index = fileRows.indexOf(data);
            if(data.id === productData.id) {
                fileRows[index] = productData;}
        }

        setRows(fileRows)

        updateProductData(productData);

        setOpenChangeModal(false);
        setProductData(initalState);

    }

    function dataFetch(request: Request) {
        fetch(request).then(
            (response) => {
                return response.json();
            }
        ).then((products) => {
            let filteredProducts = [];
            for(const prod of products) {

                prod.companyId = user.companyId;

                if(!prod.last_updated) {
                    prod.last_updated = new Date().toLocaleString('de-DE');
                }else {
                    prod.last_updated = new Date(prod.last_updated).toLocaleString('de-DE');
                }
                if(!prod.is_deleted) {
                    filteredProducts.push(prod);
                }
            }
            setRows(filteredProducts);

            if(products.length > 0) {
                notify('Produkte wurden geladen')
            } else {
                notify('Es wurden keine Produkte gefunden')
            }
            return products;
        });
    }

    return (
        <>
            <h3><FcPackage/> Produkte</h3>
            <div style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', display: 'flex'}}>
                <div style={{width: '50%'}}>
                    <p>Zum Bearbeiten von Produkten, musst Du diese zuerst hochladen.<br/></p>
                    <ol type="1">
                        <li>Klicke auf  "Data Sheet herunterladen"</li>
                        <li>Trage die Daten in die Muster-Excel ein</li>
                        <li>Klicke danach auf "Import Data Sheet"</li>
                    </ol>
                </div>
                <div style={{width: '50%'}}>
                    <p>
                        Du willst <b>nachträglich</b> Daten anpassen? Verfahre wieder wie in Schritt 1-4 beschrieben.<br/>
                        Du willst Daten <b>löschen</b>? Du kannst Datensätze markieren und mit Klick auf "Ausgewählte Datensätze löschen" diese entfernen.<br/>
                        Oder in der Excel das Flag is_deleted auf 1 setzen. Bereits gelöschte Artikel werden nicht in Excel exportiert
                    </p>
                </div>
            </div>
            <input
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                onChange={setFileRows}
            />
            <InputLabel htmlFor="contained-button-file" style={{width: '100%'}}>
                <Button variant="contained" color="primary" component="span" style={{marginBottom: '10px', marginTop: '10px', width: '100%'}}>
                    IMPORT DATA SHEET
                </Button>
            </InputLabel>
            {!isLoading && permissions.isAdmin ?
                <>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    User
                </InputLabel>
                <NativeSelect
                    defaultValue={user ? user.userId : identity?.id}
                    inputProps={{
                        name: 'user',
                        id: 'uncontrolled-native',
                    }}
                    onChange={handleAdminUserChange}
                >
                    {users.map((user) => (
                        <option key={user.user_id} value={user.user_id}>{user.email}</option>
                    ))}
                </NativeSelect> </>: null
            }
            <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
                <Button id="exportData" onClick={exportGrid}>Data-Sheet herunterladen</Button>
                <Button id="loadData" onClick={loadData}>Lade Daten</Button>

                <Button size="small" onClick={removeRow}>
                    Ausgewählte Datensätze Löschen
                </Button>
            </Stack>

            <DataGrid
                autoHeight={true}
                pageSize={10}
                checkboxSelection
                columns={columns}
                rows={rows}
                processRowUpdate={processRowUpdate}
                components={{NoRowsOverlay: MyCustomNoRowsOverlay}}
                onProcessRowUpdateError={(error) => {console.log(error)}}
                experimentalFeatures={{ newEditingApi: true }}
                onSelectionModelChange={(ids) => {
                    setRowsSelected(ids);
                }}
            />

            <Dialog open={openChangeModal} onClose={handleChangeDialogClose}>
                <DialogTitle>Produkt bearbeiten</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Ändere bereits angelegte Produkte
                    </DialogContentText>
                    <TextField
                        defaultValue={productData.brand}
                        autoFocus
                        margin="dense"
                        id="brand_name"
                        label="Marken Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.brand = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.name}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.name = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.pzn_de}
                        autoFocus
                        margin="dense"
                        id="pzn_de"
                        label="PZN_DE"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.pzn_de = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.pzn_at}
                        autoFocus
                        margin="dense"
                        id="pzn_at"
                        label="PZN_AT"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.pzn_at = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.asin}
                        autoFocus
                        margin="dense"
                        id="asin"
                        label="ASIN"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.asin = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.article_number}
                        autoFocus
                        margin="dense"
                        id="article_number"
                        label="Artikelnummer"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.article_number = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.uvp}
                        autoFocus
                        margin="dense"
                        id="uvp"
                        label="UVP"
                        type="number"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.uvp = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.keywords}
                        autoFocus
                        margin="dense"
                        id="keywords"
                        label="Keywords"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.keywords = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.gtin13}
                        autoFocus
                        margin="dense"
                        id="gtin13"
                        label="GTIN_13"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.gtin13 = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.gtin8}
                        autoFocus
                        margin="dense"
                        id="gtin8"
                        label="GTIN_8"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.gtin8 = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.isbn}
                        autoFocus
                        margin="dense"
                        id="isbn"
                        label="ISBN"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.isbn = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.amountFirst}
                        autoFocus
                        margin="dense"
                        id="amountFirst"
                        label="Erste Menge"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.amountFirst = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.unitFirst}
                        autoFocus
                        margin="dense"
                        id="unitFirst"
                        label="Erste Einheit"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.unitFirst = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.amountSecond}
                        autoFocus
                        margin="dense"
                        id="amountSecond"
                        label="Zweite Menge"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.amountSecond = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.unitSecond}
                        autoFocus
                        margin="dense"
                        id="unitSecond"
                        label="Zweite Einheit"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.unitSecond = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.img_url}
                        autoFocus
                        margin="dense"
                        id="img_url"
                        label="Bild Url"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.img_url = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.category_one}
                        autoFocus
                        margin="dense"
                        id="category_one"
                        label="Kategorie Eins"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.category_one = ev.target.value}}
                    />
                    <TextField
                        defaultValue={productData.category_two}
                        autoFocus
                        margin="dense"
                        id="category_two"
                        label="Kategorie Zwei"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(ev) => {productData.category_two = ev.target.value}}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleChangeDialogClose}>Abbrechen</Button>
                    <Button onClick={handleChangeModalConfirm}>Bestätigen</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default {
    list: SheetJSReact,
    options: { label: 'Products' },
};