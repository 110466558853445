import React from 'react';

import {FcDownload} from "react-icons/fc";
import Button from "@mui/material/Button";

const ZIP_FILE_URL = 'https://backend.prosoom.com/prosoom-search.zip'

export function DownloadsBlock() {
    return (
        <>
            <h3><FcDownload/> Downloads</h3>
            <div style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', display: 'flex'}}>
                <p>
                    Erweitere Deine Website mit Leichtigkeit, indem Du Den Buy-Button und unsere Suche integrierst - und
                    das kostenlos und in Deinem Design.
                    So kannst Du die Conversion-Rate deutlich steigern und Deine Kunden werden begeistert sein.
                    Sie finden alle Angebite des Marktes an einem Ort: Auf Deiner Website.
                    Kein lästiges Vorab-Wählen zwischen Shop A oder Shop B mehr und dann das mühsame Suchen nach Preisen
                    und Verfügbarkeiten.
                </p>
                <a
                    href={ZIP_FILE_URL}
                    download="prosoom_search_wordpress"
                    target="_blank"
                    rel="noreferrer"
                >
                    <Button variant='contained'>Prosoom-Search Wordpress Plugin</Button>
                </a>
            </div>
            <div style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', display: 'flex'}}>
                <b style={{marginTop: "15px"}}>Und so funktioniert die Einbinung Schritt für Schritt</b>
                <div style={{width: "100%"}}>

                </div>
                <div style={{width: "50%"}}>
                    <h3>1. Prosoom Backend</h3>
                    <p>1. Gehe in den Reiter "Marken" und hinterlege fort die Informationen: Marke, Logo und den
                        gewünschten Farbcode</p>
                    <p>1.1 Dazu klicke auf “Data Sheet herunterladen”.</p>

                    <img src="https://backend.prosoom.com/grafiken/1.Marken-Backend.png"
                         style={{width: "70%", height: "70%"}}/>

                    <p>
                        2. Trage Deine Daten in die Muster-Excel Tabelle ein.
                    </p>
                    <ul>
                        <li>Marke ist ein frei definierbarer Name.</li>
                        <li>Logo muss eine Internet-Speicherquelle sein. Du kannst auch den Logo-Link von Eurer Website
                            nehmen.
                        </li>
                        <li>Farbcode wird in Hexadezimalcodes angegeben. Weiß ist z.B. #FFFFFF.</li>
                    </ul>

                    <img src="https://backend.prosoom.com/grafiken/2.Excel-Tabelle.png"
                         style={{width: "70%", height: "70%"}}/>

                    <p>
                        3. Wenn Du Deine Daten hinterlegt hast, bekommst Du einen Link unter “Prosoom URL”. Den
                        benötigst Du später, also speichere ihn gut weg.
                    </p>
                    <ul>
                        <li>Beispiel: https://www.prosoom.com/product/[PRODUCTID]/?brandId=104.</li>
                    </ul>
                    <img src="https://backend.prosoom.com/grafiken/3.Fertige Marke.png"
                         style={{width: "70%", height: "70%"}}/>
                    <p>
                        4. Lade das Wordpress-Plugin in aus dem Reiter “Downloads” herunter. Es ist eine ZIP-Datei.
                    </p>

                    <img src="https://backend.prosoom.com/grafiken/4.Wordpress-Plugin downloaden.png"
                         style={{width: "70%", height: "70%"}}/>
                </div>
                <div style={{width: '50%'}}>
                    <h3>2. Wordpress Backend</h3>

                    <p>
                        5. Gehe in Dein Word-Press-Backend unter “Plugins” und wähle dort “installieren”. Wähle die
                        zuvor heruntergeladene Datei aus und folge dem Assistenten.
                    </p>

                    <img src="https://backend.prosoom.com/grafiken/5.Wordpress Backend Plugin installieren.png"
                         style={{width: "70%", height: "70%"}}/>

                    <p>
                        6. Danach erscheint in dem Seiten-Editor unter den Komponenten auch “Prosoom BuyButton” und
                        “Prosoom Search”.
                    </p>
                    <ul>
                        <li>einfach per Klick einbinden</li>
                    </ul>

                    <img src="https://backend.prosoom.com/grafiken/6. Wordpress-Editor.png"
                         style={{width: "70%", height: "70%"}}/>

                    <p>
                        7. Nun nur noch in den Einstellungen die benötigten Daten einpflegen und schon ist Dein
                        Prosoom-Button fertig.
                    </p>

                    <img src="https://backend.prosoom.com/grafiken/7. Einstellungen Button.png"
                         style={{width: "70%", height: "70%"}}/>


                    <p>BrandID kannst Du von dem zuvor im Schritt 2 erstellten Link entnehmen.</p>

                    <img src="https://backend.prosoom.com/grafiken/8. Brand ID.png"
                         style={{width: "70%", height: "70%"}}/>

                    <p>ProductID ist ebenfalls eine Nummer, die Du aus der Prosoom-Suche entnehmen kannst. Einfach
                        Dein Produkt soomen und dann aus der URL die Artikelnummer entnehmen.
                    </p>

                    <img src="https://backend.prosoom.com/grafiken/9. Product ID.png"
                         style={{width: "70%", height: "70%"}}/>
                </div>
            </div>
        </>
    )
}

export default {
    list: DownloadsBlock,
    options: {label: 'Downloads'},
};

/*<div>
    <h3>Prosoom-Suche Analytics</h3>
    <p>
        Jeder Klick auf einen Link erzeugt eine Session, mit der alle Query Parameter gespeichert
        werden.
    </p>
    <p>
        Eine Session beinhaltet weitere Werte:
    </p>
    <ul>
        <li>started_at: Wann wurde die Session gestartet</li>
        <li>browser: Welchen Browser nutzt der User</li>
        <li>operating_system: Welches Betriebsystem nutzt der User</li>
        <li>device_type: Welches Endgerät nutzt der User</li>
        <li>iframe: Ist die URL in einem iframe geladen worden (Hier startet die Session automatisch,
            wenn das iframe lädt)
        </li>
        <li>referrer: Hostname, von welcher die Anfrage kam. (Bei iframe, wo das iframe geladen wurde)
        </li>
    </ul>
    <p>
        Query Parameter für die Analytics
    </p>
    <ul>
        <li>brandId: Ändert das Design der Suche (Logo + Farben)</li>
        <li>Erstellbar im Prosoom-Backend</li>
        <li>referrerType: Frei wählbarer Typ der Herkunft(max. 25 Zeichen) z.B. searchButton, buyButton,
            adButton
        </li>
        <li>referrerId: Verweist auf die Herkunft, auf der der Link platziert wurde. Wichtig, da Ads oft
            in einem Container geladen werden, die nicht die URL der eigentlichen Webseite haben
        </li>
        <li>Erstellbar im Prosoom-Backend</li>
        <li>adType: Frei wählbarer Typ (max. 25 Zeichen) z.B. spezialAd oder html5Ad</li>
    </ul>
    <p>
        Erklärung & Beispiel Analytics für Suchergebnisse
    </p>
    <ul>
        <li>https://www.prosoom.com/search?q=mirfulan&brandId=130&referrerType=searchButton&referrerId=1&adType=spezialAd</li>
    </ul>

    <p>
        Mit der Suchanfrage werden folgende Werte gespeichert:
    </p>
    <ul>
        <li>
            search_term: q Query Wert aus der URL. Wenn ein User weitere Begriffe sucht, erzeugt dies
            einen neuen Eintrag mit seiner Session Id.
        </li>
        <li>refer_to_item: Klickt ein User auf ein Produkt, wird diese ItemId gespeichert</li>
    </ul>

    <p>
        Erklärung & Beispiel Analytics für Produkte
    </p>

    <ul>
        <li>https://www.prosoom.com/product/267156?brandId=130&referrerType=buyButton&referrerId=1&adType=spezialAd</li>
    </ul>

    <p>Mit dem Produkt Aufruf werden folgende Werte gespeichert:</p>

    <ul>
        <li>search_term: q Query Wert aus der URL oder Begriff mit dem er das Produkt gefunden hat.</li>
        <li>refer_to_item: ItemId des Produkts</li>
        <li>
            called_local_offers: Wenn verfügbar, wird gespeichert, wenn der User die Lokale Suche
            aufgerufen hat
        </li>
    </ul>

    <p>
        Mit dem Klick auf einen Shop werden folgenden Informationen gespeichert:
    </p>

    <ul>
        <li>Welchen Shop er ausgewählt hat</li>
        <li>Alle sichtbaren Shops</li>
        <li>Marketplace Id</li>
        <li>Shop Id</li>
        <li>Preis</li>
        <li>Url</li>
        <li>Alle angewendeten Filter und Sortierungen</li>
    </ul>
</div>*/