import React, {useEffect, useState} from "react"
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import Row from "./Row";
import axios from "axios";
import {useGetIdentity} from "react-admin";

function AuctionTable() {
    const { identity } = useGetIdentity();
    const [auctions, setAuctions] = useState<[{id: number}] | []>([]);
    const [isLoading, setIsLoading] = useState(true)
    const [reload, setReload] =  React.useState(false)
    const [userId, setUserId] = React.useState(null)

    useEffect(() => {
        // @ts-ignore
        setUserId(identity?.id)
        getCarts()
        setIsLoading(false)
    }, [reload, userId]);

    useEffect(() => {
        let intervallId: string | number | NodeJS.Timer | undefined;
        if(userId){
            intervallId = setInterval(() => {
                getCarts()
            },5000)
        }
        return () => {
            // @ts-ignore
            clearInterval(intervallId)
        }
    },[userId])

    const getCarts = () => {
        axios
            .get(`${process.env.REACT_APP_AUCTION_SERVICE}/dealer-carts/${userId}`)
            .then((res) => {
                if(res.data){
                    setAuctions(res.data)
                }
            })
            .catch((e) => {
                console.log(e);
            })
    }

    return (
        <>
            {isLoading && <Typography>Lade Warenkörbe...</Typography>}
            {
                (!isLoading && auctions.length > 0) &&
                    <TableContainer component={Paper} sx={{borderRadius: 5}}>
                    <Table aria-label="collapsible table">
                        <TableHead sx={{"& th": {textAlign: "center", fontWeight: "bold"}}}>
                            <TableRow>
                                <TableCell />
                                <TableCell>Produkte</TableCell>
                                <TableCell>Restlaufzeit</TableCell>
                                <TableCell>Wunschpreis</TableCell>
                                <TableCell>Aktuelles Gebot</TableCell>
                                <TableCell>Neues Gebot</TableCell>
                                <TableCell>Info</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {auctions.map((cart) => (
                                <Row key={cart.id} cart={cart} reload={reload} setReload={setReload} userId={userId} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {
                (!isLoading && auctions.length === 0 &&
                    <Typography variant={"body1"} p={2}>
                        Zur Zeit gibts es keine Warenkörbe
                    </Typography>)
            }
        </>
    )
}

export default AuctionTable