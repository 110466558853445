import * as React from 'react';
import {useContext, useState} from 'react';
import {useLogin, useNotify} from 'react-admin';
import {Box, Button, Container, Divider, TextField, Typography} from "@mui/material";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {CompanyContext} from "../Context/CompanyContext";

const LoginPage = ({}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();

    const {setActiveCompanyId} = useContext(CompanyContext);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const userData = await login({username: email, password}).catch(() =>
            notify('Deine E-Mail oder dein Passwort sind falsch')
        );

        if(userData.companyId != null) {
            setActiveCompanyId(userData.companyId)
        }

    };

    return (
        <Box
            id={"startBox"}
            sx={{
                position: "relative",
                backgroundImage: "url(/bg-home.jpg)",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100vh",
                maxHeight: "-webkit-fill-available" // Because of iOS Safari menu bar bug
            }}>
            <Container maxWidth={"md"} sx={{
                position: "relative",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: {xs: "space-around", md: "center"},
                px: {xs: 0, sm: "16px"}
            }}>
                <Box component={"form"} onSubmit={handleSubmit} sx={{
                    bgcolor: "rgba(234,234,255,0.8)",
                    width: "100%",
                    borderRadius: {xs: 0, sm: "30px", md: "50px"},
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    flexDirection: "column",
                    p:5
                }}>
                    <Box component={"img"} sx={{width: {xs: "65%", sm: "50%"}, paddingBottom: {xs: 1, sm: 3}}} src={"/prosoom_logo.svg"} alt={"prosoom_logo"}/>
                    <Box sx={{position: "relative"}}>
                        <Typography variant="h5" component="h1" sx={{fontWeight: "bold", color: "#575D7C"}}>
                            Finde dein Produkt zum besten Preis – in realtime
                        </Typography>
                        <Box component={"img"} sx={{
                            width: {xs: "60px", sm: "95px", md: "120px"},
                            position: "absolute",
                            bottom: 0,
                            right: 0
                        }} src={"/underscore.png"} alt={"prosoom_logo"}/>
                    </Box>
                    <Typography variant={"h6"} fontWeight={"bold"} borderBottom={"1px solid"} alignSelf={"flex-start"} width={"100%"} mt={5} mb={2} sx={{ color: "#575D7C"}}>Bei Deinem Prosoom-Backend-Konto anmelden</Typography>
                    <TextField autoComplete={"email"} name="email" label="E-Mail" variant="outlined" type={"email"} value={email} fullWidth inputProps={{style: {backgroundColor: '#f6f9ff'}}} onChange={(e) => setEmail(e.target.value)}/>
                    <TextField autoComplete="password" name="password" label="Passwort" variant="outlined" type={"password"} value={password} fullWidth inputProps={{style: {backgroundColor: '#f6f9ff'}}} onChange={(e) => setPassword(e.target.value)}/>
                    <Button variant="contained" startIcon={<PersonOutlineOutlinedIcon/>} type={"submit"} sx={{alignSelf: "flex-end", mt: 2}}>Anmelden</Button>
                </Box>
        </Container>
        </Box>
    );
};

export default LoginPage;