import {
    Alert,
    Box,
    Button,
    CardMedia, CircularProgress,
    Collapse,
    IconButton, InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, {useEffect, useState} from "react";
import axios from "axios";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GavelIcon from "@mui/icons-material/Gavel";
import EmailIcon from '@mui/icons-material/Email';
import MakeBidDialog from "./MakeBidDialog";
function Row(props: any) {
    const { cart, reload ,setReload, userId } = props;
    const [open, setOpen] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const [loadingImmediatelyDeal, setLoadingImmediatelyDeal] = React.useState(false)

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_SHOPPING_CARD_SERVICE}/cart/get-cart-items/${cart.id}/${cart.userId}`)
            .then((res) => {
                if (res.data) {
                    console.log(res.data);
                    setItems(res.data);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }, [reload]);

    const handleImmediatelyDeal = () => {
        setLoadingImmediatelyDeal(true)
        axios
            .post(`${process.env.REACT_APP_AUCTION_SERVICE}/immediately-deal`,
                {
                    cartId: cart.id,
                    userId: userId,
                })
            .then((res) => {
                console.log(res)
                setTimeout(() => {
                    setReload(!reload)
                }, 1500)
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                setLoadingImmediatelyDeal(false)
            });
    }

    const getBackgroundColor = () => {
        if(cart.leadingBidder) return "#f4f9ed"
        //if(cart.currentBid && !cart.leadingBidder) return "#fdf4ee"
        return ""
    }

    return (
        <>
            <TableRow sx={{ backgroundColor: getBackgroundColor, "& td": {textAlign: "center"}}}>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell>{items.length}</TableCell>
                <TableCell>{!cart.auctionFinished ? <CountDown endTime={cart.auctionExpiresAt}/> : "Auktion beendet"}</TableCell>
                <TableCell>{cart.wishPrice} €</TableCell>
                <TableCell sx={{fontWeight: cart.currentBid ? "bold": null}}>{cart.currentBid ? cart.currentBid + " €": "-"}</TableCell>
                <TableCell>
                    {!cart.auctionFinished && !cart.leadingBidder ?
                        <MakeBidDialog currentBid={cart.currentBid} wishPrice={cart.wishPrice} userId={userId} cartId={cart.id} setReload={setReload} reload={reload}/>
                       /* <Box sx={{width: "fit-content", margin: "0 auto"}}>
                            <TextField
                            disabled={makeNewBid}
                            type={"number"}
                            inputProps={{
                                min: 0.01,
                                step: 0.01,
                                max: cart.currentBid ? cart.currentBid - 0.01 : 99999999
                            }}
                            size={"small"}
                            sx={{width: "150px", "& input": {p: 0.5}}}
                            value={newBid}
                            onChange={(e) => setNewBid(e.target.value)}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}></TextField>
                            <Button sx={{py: 0, mt: 0.5}} onClick={handleMakeBid} disabled={makeNewBid || newBid == "" || (cart.currentBid ? parseFloat(newBid) > cart.currentBid - 0.01 : false)}>
                            Bieten
                            {makeNewBid && <CircularProgress sx={{ml: 1}} size={20}/>}
                            </Button>
                            {cart.currentBid && !cart.leadingBidder && !cart.auctionFinished && <Box textAlign={"left"}>
                            <Typography variant={"caption"}>Maximal {cart.currentBid - 0.01} €</Typography>
                        </Box>}
                    </Box>*/ : "-"}
                </TableCell>
                <TableCell sx={{"& > div":{margin: "0 auto"}}}>
                    {cart.leadingBidder && !cart.auctionFinished &&
                        <Alert sx={{width: "fit-content", py:0.5}}>Du hast das beste Gebot 🎉</Alert>
                    }
                    {cart.leadingBidder && cart.auctionFinished &&
                        <Alert sx={{width: "fit-content", py:0.5, "& .MuiAlert-icon":{alignItems: "center"}}}>Warenkorb gewonnen 🎉 <br/>Sende dem Benutzer eine E-Mail mit weiteren Info´s.</Alert>
                    }

                    {!cart.leadingBidder && cart.auctionFinished && cart.currentBid && cart.bitOnThisCart &&
                        <Alert severity={"info"} sx={{width: "fit-content", py:0.5}}>Warenkorb nicht gewonnen</Alert>
                    }

                </TableCell>
                <TableCell sx={{textAlign:"right !important"}}>
                    {!cart.auctionFinished && <Button
                        disabled={(cart.currentBid ? cart.currentBid < parseFloat(cart.wishPrice) : false) || cart.auctionFinished || loadingImmediatelyDeal}
                        variant={"contained"}
                        color={"success"}
                        sx={{color: "white"}}
                        endIcon={!loadingImmediatelyDeal && <GavelIcon />}
                        onClick={handleImmediatelyDeal}
                        fullWidth
                    >
                        Sofort Deal!
                        {loadingImmediatelyDeal && <CircularProgress sx={{ml: 1}} size={20}/>}
                    </Button>}
                    {cart.leadingBidder && cart.auctionFinished &&
                        <Button fullWidth endIcon={<EmailIcon/>} variant={"outlined"}
                                href={`mailto:${cart.emailCartUser}?subject=Angebot%20zum%20Warenkorb%20in%20der%20Prosoom%20B%C3%B6rse`}>E-Mail an Benutzer</Button>
                    }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell sx={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Produkte im Warenkorb
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell/>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Hersteller</TableCell>
                                        <TableCell>Artikelnummer</TableCell>
                                        <TableCell>Anzahl</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{"& > tr:last-child > td":{borderBottom: "unset !important"}}}>
                                    {items.map((product: any) => (
                                        <TableRow key={product.itemId}>
                                            <TableCell>
                                                <CardMedia
                                                onError={addDefaultSrc}
                                                component="img"
                                                src={getImageUrl(product.itemId)}
                                                alt={"img"}
                                                sx={{ objectFit: "contain", maxHeight: 50 }}
                                            /></TableCell>
                                            <TableCell sx={{fontWeight: "bold"}} scope="row">
                                                {product.itemName}
                                            </TableCell>
                                            <TableCell>{product.manufacturerName}</TableCell>
                                            <TableCell>
                                                {(product.gtin13 || product.gtin8) && "GTIN: "}
                                                {product.gtin13} {product.gtin13 && product.gtin8 && " | "} {product.gtin8}
                                                {(product.gtin13 || product.gtin8) && (product.pznDe || product.pznAt) && " • "}
                                                {(product.pznDe || product.pznAt) && "PZN: "}
                                                {product.pznDe} {product.pznDe && product.pznAt && " | "} {product.pznAt}
                                            </TableCell>
                                            <TableCell>{product.amount}</TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    size={"small"}
                                                    variant={"contained"}
                                                    href={`https://www.prosoom.com/product/${product.itemId}`}
                                                    target={"_blank"}
                                                    endIcon={<ArrowForwardIcon />}
                                            >
                                                Zum Produkt
                                            </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default Row

// @ts-ignore
const CountDown = ({ endTime }) => {
    const countDownDate = new Date(endTime).getTime();
    const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    const now = new Date().getTime();

    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    /*return <React.Fragment>{countDown > 0
        ? `${days > 0 ? days + "T" : ""} ${hours > 0 ? hours + "Std" : ""} ${minutes > 0 ? minutes + "Min" : ""} ${seconds > 0 ? seconds + "S" : ""}`
        : "Auktion beendet"
    }</React.Fragment>    */

    return <React.Fragment>{ countDown > 0
        ? `${days > 1 ? days + " Tage" : ""} ${days === 1 ? days + " Tag" : ""}
        ${hours > 1 ? hours + " Stunden" : ""} ${hours === 1 ? hours + " Stunde" : ""}
        ${minutes > 1 ? minutes + " Minuten" : ""} ${minutes === 1 ? minutes + " Minute" : ""}
        ${seconds > 0 && days < 0 && hours < 0 ? seconds + " Sekunden" : ""}`
        : "Auktion beendet"
    }</React.Fragment>
};

function getImageUrl(itemId: string) {
    let urlString = "/img_placeholder.jpg";
    if (itemId) {
        urlString = `https://product-images.prosoom.com/pharmacies/${itemId}.webp`;
        return urlString;
    }
    return urlString;
}

function addDefaultSrc(ev: any){
    ev.target.src = "/img/img_placeholder.jpg";
}