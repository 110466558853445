import {
    Alert,
    Button, Checkbox,
    CircularProgress,
    Dialog, DialogActions,
    DialogTitle, FormControlLabel,
    Grid, InputAdornment, TextField,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import axios from "axios";

function MakeBidDialog(props: any) {
    const {currentBid, wishPrice, cartId, userId, setReload, reload} = props;
    const [open, setOpen] = useState(false)
    const [bid, setBid] = useState("")
    const [shippingCosts, setShippingCosts] = useState("")
    const [deliveryTime, setDeliveryTime] = useState("")
    const [freeShipping, setFreeShipping] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")

    const handleMakeBid = () => {
        setError("")
        setIsLoading(true)
        axios.post(`${process.env.REACT_APP_AUCTION_SERVICE}/make-bid/`,
            {
                cartId: cartId,
                bidderUserId: userId,
                amount: parseFloat(bid),
                shippingCosts: parseFloat(shippingCosts),
                deliveryTime: parseInt(deliveryTime)
            })
            .then(() => {
                setReload(!reload)
                setIsLoading(false)
                setOpen(false)
            })
            .catch((e) => {
                setIsLoading(false)
                setError(e.message)
            })
    }

    const handleCheckFreeShipping = (e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setFreeShipping(e.target.checked)
        setShippingCosts("")
    }

    const handleChangeBid = (e: any) => {
        setBid(e.target.value)
        /*if(currentBid){
            if(parseFloat(e.target.value) < parseFloat(currentBid)) {
                setBid(e.target.value)
            }
        }else if(e.target.value == ""){
            setBid(e.target.value)
        }
        else {
            setBid((currentBid - 0.01).toString())
        }*/
    }

    function checkIfMakeBitButtonDisabled() {
        if(bid === "") return true
        if(currentBid){
            if(parseFloat(bid) >= parseFloat(currentBid)) return true
        }
        if(deliveryTime == "") return true
        if(shippingCosts == "" && !freeShipping) return true
        if(isLoading) return true
        return false
    }


    return (
        <>
            <Button variant={"contained"} onClick={() => setOpen(true)}>
                Jetzt bieten!
            </Button>
            <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth={"sm"}>
                <DialogTitle>Neues Gebot für Auktion abgeben</DialogTitle>
                <Grid container px={3} sx={{"& .MuiTextField-root":{my:1}}}>
                    <Alert severity={"info"} sx={{mb:2}}>
                        - Das aktuelle Gebot liegt bei {currentBid}€
                    <br/>
                        - Der Wunschpreis ist {wishPrice}€
                        {currentBid &&
                            <>
                                <br/>
                                - Maximal {(currentBid - 0.01).toFixed(2)}€ eingeben
                            </>
                        }
                    </Alert>
                    <Grid item xs={12}>
                        <TextField
                            InputProps={{
                                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            }}
                            fullWidth
                            inputProps={{ min: 0.01, step: 0.01, max: currentBid ? currentBid - 0.01 : 99999999 }}
                            label={"Gebot"}
                            type={"number"}
                            value={bid}
                            required
                            onChange={handleChangeBid}
                        />
                    </Grid>
                    <Grid item xs={6}>
                    <TextField
                        disabled={freeShipping}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        }}
                        fullWidth
                        inputProps={{ min: 0.01, step: 0.01 }}
                        label={"Versandkosten"}
                        type={"number"}
                        value={shippingCosts}
                        required={!freeShipping}
                        onChange={(e) => setShippingCosts(e.target.value)}
                    />
                    </Grid>
                    <Grid item xs={6} sx={{display: "flex", alignItems: "center", pl: 2}}>
                        <FormControlLabel control={<Checkbox checked={freeShipping} onChange={handleCheckFreeShipping} />} label="Kostenloser Versand" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Tag/e</InputAdornment>,
                            }}
                            fullWidth
                            inputProps={{ min: 1, step: 1 }}
                            label={"Lieferzeit"}
                            type={"number"}
                            value={deliveryTime}
                            required
                            onChange={(e) => setDeliveryTime(e.target.value)}
                        />
                    </Grid>
                    {error && <Typography color={"error"}>{error}</Typography>}
                </Grid>
                <DialogActions sx={{ justifyContent: "center", my: 2 }}>
                    <Button
                        disabled={checkIfMakeBitButtonDisabled()}
                        sx={{ color: "white" }}
                        variant={"contained"}
                        color={"warning"}
                        onClick={handleMakeBid}
                    >
                        Verbindliches Gebot jetzt abgeben
                        {isLoading && <CircularProgress size={20} sx={{ ml: 1 }} />}
                    </Button>
                </DialogActions>
               {/* {isReleased && (
                    <Alert sx={{ m: 2 }}>
                        <AlertTitle>Dein Warenkorb ist nun an der Prosoom-Börse freigegeben</AlertTitle>
                        <AlertContext>
                            Nun sind die Händler an der Reihe. Warte bis wir dich benachrichtigen, dass es ein neuen Gebot gibt. Sobald ein Gebot
                            vorliegt, kannst du entscheiden. Nimmst du das Gebot an oder wartest du ob ein weiterer Händler ein Gebot macht?
                        </AlertContext>
                    </Alert>
                )}*/}
                <DialogActions sx={{ justifyContent: "flex-start" }}>
                    <Button onClick={() => setOpen(false)}>
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
    
}

export default MakeBidDialog;