import React, {useState} from 'react';
import {Admin, Resource} from 'react-admin';

import authProvider from './components/auth/authProvider';
import usersConfiguration from './components/users'
import productConfiguration from './components/productData';
import brandConfiguration from "./components/brandData";
import brandsImageUpload from "./components/brandData/brand-img-upload";
import insightsConfiguration from "./components/insights";
import userBaseDataConfiguration from "./components/userBaseData";
import shopsConfiguration from "./components/shops";
import {Layout} from "./layout";
import LoginPage from "./components/auth/loginPage";
import welcomeConfiguration from "./components/welcome";
import boerseConfiguration from "./components/boerse";
import {defaultProsoomTheme} from "./layout/themes";
import dealerBrandConfiguration from "./components/dealerBrands";
import dealerProductConfiguration from "./components/dealerProducts";
import downloadsConfiguration from "./components/downloads";
import iqviaConfiguration from "./components/iqvia";
import referrerConfiguration from "./components/referrerData";
import crudProvider from 'ra-data-nestjsx-crud'
import {fetchUtils} from "ra-core";
import {CompanyContext} from "./components/Context/CompanyContext";

const fetchJson = (url: string, options: fetchUtils.Options = {}) => {
        const customHeaders = (options.headers ||
            new Headers({
                    Accept: "application/json",
            })) as Headers;
        //customHeaders.set("X-Custom-Header", "foobar");
        options.headers = customHeaders;
        options.credentials = "include";
        return fetchUtils.fetchJson(url, options);
};
// @ts-ignore
const dataProvider = crudProvider(process.env.REACT_APP_BACKEND_API, fetchJson);

const App = () => {
    const [activeCompanyId, setActiveCompanyId] = useState(0);

    return (
        <CompanyContext.Provider value={{activeCompanyId, setActiveCompanyId}}>
            <Admin loginPage={LoginPage} authProvider={authProvider} dataProvider={dataProvider} requireAuth={true}
                   layout={Layout} theme={defaultProsoomTheme}>
                <Resource name='welcome' {...welcomeConfiguration} />
                <Resource name='insights' {...insightsConfiguration}/>
                <Resource name='companies' {...userBaseDataConfiguration} />
                <Resource name="products" {...productConfiguration}/>
                <Resource name="brands" {...brandConfiguration} />
                <Resource name="brandsImageUpload" {...brandsImageUpload} />

                <Resource name="shops" {...shopsConfiguration} />
                <Resource name="boerse" {...boerseConfiguration} />
                <Resource name="users" {...usersConfiguration} />

                <Resource name="dealer_products" {...dealerProductConfiguration} />
                <Resource name="dealer_brands"  {...dealerBrandConfiguration} />
                <Resource name="downloads" {...downloadsConfiguration} />
                <Resource name="iqvia_upload" {...iqviaConfiguration} />
                <Resource name="referrer" {...referrerConfiguration} />
            </Admin>
        </CompanyContext.Provider>
    )
};

/*
#<Resource name="competitor" {...competitorProductsConfiguration} />
# <Resource name="measures"  list={Measures} />
#
 */

export default App;